//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { mapGetters } from 'vuex'
import { imageLoadError } from '@/helpers/image'

export default {
  name: 'HomeOppdayUpcoming',
  props: {
    itemsList: {
      type: Array,
      default: Array
    },
    eventTrackingSymbolName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      carouselOptions: {
        loop: false,
        showPagination: false,
        showNavigationOutSide: true,
        slidesPerView: 'auto',
        spaceBetween: 0,
        centeredSlides: false,
        grabCursor: true,
        resizeObserver: true,
        navigation: {
          nextEl: '.oppday-upcoming .swiper-btn-next',
          prevEl: '.oppday-upcoming .swiper-btn-prev'
        },
        pagination: {
          el: '.oppday-upcoming .swiper-pagination',
          dynamicBullets: true,
          clickable: true,
          renderBullet: (index, className) => {
            return (
              '<span class="' +
              className +
              '"><div class="circle"></div></span>'
            )
          }
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
            showNavigation: false,
            showNavigationOutSide: false
          },
          577: {
            slidesPerView: 2,
            showNavigation: false,
            showNavigationOutSide: false
          },
          768: {
            slidesPerView: 3
          },
          1025: {
            slidesPerView: 4
          }
        }
      }
    }
  },
  computed: {
    // ...mapGetters({
    //   homeOppdayUpcoming: 'home/oppday/getHomeOppdayUpcoming'
    // }),
    swiper () {
      return this.$refs.oppdayUpcoming.swiper
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.onSwiperInit()
    })
  },
  methods: {
    imageLoadError,
    onSwiperInit () {
      this.swiper.on('breakpoint', (e) => {
        this.swiper.update()
      })
    },
    onClickSymbolName (quoteSymbol, companyName, status) {
      const evtName = this.eventTrackingSymbolName !== ''
        ? this.eventTrackingSymbolName
        : status === 'live'
          ? this.$i18n.locale === 'th'
            ? 'homeOppdayLive'
            : 'homeDigitRsLive'
          : this.$i18n.locale === 'th'
            ? 'homeOppdayUpcoming'
            : 'homeDigitRsUpcoming'
      const evtOption = this.eventTrackingSymbolName !== ''
        ? { symbol: quoteSymbol, companyName }
        : { title: `${quoteSymbol} : ${companyName}` }
      this.$personalized.clickElement({
        name: evtName,
        optionLang: evtOption
      })
    }
  }
}
