import { render, staticRenderFns } from "./Oppday.vue?vue&type=template&id=ac129186&"
import script from "./Oppday.vue?vue&type=script&lang=js&"
export * from "./Oppday.vue?vue&type=script&lang=js&"
import style0 from "./Oppday.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeOppdayUpcoming: require('/app/components/Pages/Home/Oppday/Upcoming.vue').default,HomeOppdayArchive: require('/app/components/Pages/Home/Oppday/Archive.vue').default,Oppday: require('/app/components/Oppday/index.vue').default,Button: require('/app/components/Button/Button.vue').default,ModalVideo: require('/app/components/Modal/Video.vue').default})
