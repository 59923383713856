import { render, staticRenderFns } from "./More.vue?vue&type=template&id=c7862118&"
import script from "./More.vue?vue&type=script&lang=js&"
export * from "./More.vue?vue&type=script&lang=js&"
import style0 from "./More.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Divider: require('/app/components/Divider.vue').default,IconChevronLeft: require('/app/components/Icon/ChevronLeft.vue').default,Button: require('/app/components/Button/Button.vue').default,IconChevronRight: require('/app/components/Icon/ChevronRight.vue').default,PlaceholderCardImage: require('/app/components/Placeholder/CardImage.vue').default,Picture: require('/app/components/Picture/Picture.vue').default,SwiperCarousel: require('/app/components/SwiperCarousel/SwiperCarousel.vue').default})
