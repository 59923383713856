//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ListIndex',
  props: {
    listIndex: {
      type: Array,
      default: Array
    },
    handleSelect: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      active: 0
    }
  },
  methods: {
    isActive (active) {
      return this.active === active ? 'active' : ''
    },
    setActive (active, index) {
      this.handleSelect(index)
      this.active = active
    }
  }

}
