//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'HomeInFocusHighlight',
  props: {
    infocusData: {
      type: Object,
      default: Object
    }
  },
  computed: {
    images () {
      return this.infocusData.image || []
    },
    target () {
      if (this.infocusData.openNewTab === true) {
        return '_blank'
      }
      return null
    }
  },
  methods: {
    eventTrackingClicked () {
      if (this.infocusData.url && this.infocusData.url !== '#') {
        this.$personalized.clickElement({
          name: 'home13',
          optionLang: {
            menu: this.infocusData.name || ''
          }
        })
      }
    }
  }
}
