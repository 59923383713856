//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'VideoPlayer',
  props: {
    youtubeid: {
      type: String,
      default: ''
    },
    videoUrl: {
      type: String,
      default: null
    },
    videoThumbnail: {
      type: Array,
      default: null
    },
    playerUrl: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: () => ''
    },
    imageSize: {
      type: String,
      default: 'mq'
    },
    playOnModal: {
      type: Boolean,
      default: false
    },
    showIconPlay: {
      type: Boolean,
      default: true
    },
    live: {
      type: Boolean,
      default: false
    },
    playlist: {
      type: Object,
      default: () => {}
    },
    pageUuid: {
      type: String,
      default: ''
    },
    isExternalUrl: {
      type: Boolean,
      default: false
    },
    contentTitle: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      isPlay: false,
      isServerRender: true,
      playerVars: {
        autoplay: 0,
        playsinline: 1,
        rel: 0
      }
    }
  },
  computed: {
    player () {
      return this.$refs.youtubePlayer.player
    },
    videoId () {
      if (this.videoUrl) {
        return this.$youtube?.getIdFromUrl(this.videoUrl)
      }
      return this.youtubeid || ''
    }
  },
  created () {
    this.isServerRender = process.server
  },
  mounted () {
    this.$nextTick(() => {
      this.handleClickPlay()
    })
  },
  methods: {
    readyYoutube () {
      this.handleClickPlay()
    },
    handleClickPlay () {
      if (this.$route.query.play) {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' })
        this.playerVars.autoplay = 1
        this.playerVars.playsinline = 1
        this.$nextTick(() => {
          this.playVideo()
        })
      }
    },
    videoEnded () {
      const currentIndex = this.playlist.items.find(e => e.playing === true).order
      if (currentIndex + 1 < this.playlist.items.length) {
        const nextURL = this.playlist.items[currentIndex + 1].url
        const url = new URL(nextURL)
        this.$router.push({ path: url.pathname, query: { play: 1 } })
      }
    },

    playVideo (id) {
      this.$emit('handleEventTrackingPlayVideo')
      const playOnModal = this.playOnModal
      if (this.playerUrl && !playOnModal) {
        window.open(this.playerUrl, '_blank')
      } else if (playOnModal) {
        this.showModal(id)
      } else {
        // this.handleClickTracking()
        this.isPlay = true
        this.$nextTick(() => {
          this.player.playVideo()
        })
      }
    },
    showModal (id) {
      if (!id) { return }
      this.$emit('openModal', id)
    },
    onViewContent () {
      this.$emit('handleEventTracking')
      if (this.isExternalUrl) {
        const payload = {
          pageUuid: this.pageUuid
        }
        this.$content.addPageView(payload)
      }
      window.open(this.playerUrl, '_blank')
    },
    handleClickTracking () {
      this.$personalized.clickElement({
        name: 'videoDetail',
        optionLang: {
          contentTitle: this.contentTitle
        }
      })
    }
  }
}
