import { render, staticRenderFns } from "./ChartMai.vue?vue&type=template&id=063c8dcc&"
import script from "./ChartMai.vue?vue&type=script&lang=js&"
export * from "./ChartMai.vue?vue&type=script&lang=js&"
import style0 from "./ChartMai.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconLinkStock: require('/app/components/Icon/LinkStock.vue').default,IconTriangle: require('/app/components/Icon/Triangle.vue').default,PlaceholderList: require('/app/components/Placeholder/List.vue').default,Placeholder: require('/app/components/Placeholder/Placeholder.vue').default,Charts2LineMarket: require('/app/components/Charts2/LineMarket.vue').default})
