//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import cloneDeep from 'lodash/cloneDeep'
import { mapGetters, mapActions } from 'vuex'
import { formatDateTimeLong } from '~/helpers/dateTime'
export default {
  name: 'MarketUpdate',
  props: {
    tabName: {
      type: String,
      default: 'SET'
    },
    handleSelect: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      topRankingStocks: [],
      isClient: process.client,
      swiperOptions: {
        slidesPerView: 'auto',
        watchOverflow: true,
        showPagination: false,
        freeMode: true,
        resizeObserver: true,
        slidesPerGroup: 1,
        spaceBetween: 16,
        breakpoints: {
          // 768: {
          //   slidesPerView: 'auto'
          // }
          1200: {
            slidesPerView: 5,
            slidesPerGroup: 5
          }
        }
      },
      loading: false,
      topRankData: null,
      payloadSET: {
        index: 'SET'
      },
      payloadTopRanking: {
        index: 'SET',
        rankingType: 'mostActiveValue',
        securityType: 'S',
        count: '5'
      },
      listTopRank: [
        // { title: this.$i18n.locale === 'th' ? 'มูลค่าซื้อขาย' : 'MostActiveValue', rankingType: 'mostActiveValue' },
        // { title: this.$i18n.locale === 'th' ? 'ปริมาณซื้อขาย' : 'MostActiveVolume', rankingType: 'mostActiveVolume' },
        // { title: this.$i18n.locale === 'th' ? 'ราคาเพิ่มขึ้น' : 'TopGainer', rankingType: 'topGainer' },
        // { title: this.$i18n.locale === 'th' ? 'ราคาลดลง' : 'TopLoser', rankingType: 'topLoser' },
        // { title: this.$i18n.locale === 'th' ? 'ค้นหายอดนิยม' : 'PopularSearch', rankingType: 'popularSearch' }
        {
          title: this.$t('homePage.marketUpdate.topRankActiveValue'),
          rankingType: 'mostActiveValue'
        },
        {
          title: this.$t('homePage.marketUpdate.topRankActiveVolume'),
          rankingType: 'mostActiveVolume'
        },
        {
          title: this.$t('homePage.marketUpdate.topRankGainers'),
          rankingType: 'topGainer'
        },
        {
          title: this.$t('homePage.marketUpdate.topRankLosers'),
          rankingType: 'topLoser'
        },
        {
          title: this.$t('homePage.marketUpdate.topRankPopularSearch'),
          rankingType: 'popularSearch'
        }
      ],
      titleRankType: '',
      // listIndex: [],
      FTSElistIndex: [],
      indexInfo: null,
      // loadingInfo: false,
      loadingRanking: false,
      loadingChart: false,
      indexChartData: null,
      // topRanking: [],
      dataMarket: null,
      heightCollapse: 'auto',
      tabActive: 'set',
      setIndexFilterActive: 'set'
    }
  },
  async fetch () {
    await this.getInitData()
    this.topRankingStocks = this.topRanking.stocks
  },
  computed: {
    ...mapGetters({
      listIndexGetters: 'home/set-mai/getListIndex',
      indexInfoGetters: 'home/set-mai/getIndexInfo',
      indexChartDataGetters: 'home/set-mai/getIndexChartData',
      topRankingGetters: 'home/set-mai/getTopRanking',
      FTSEStock: 'market/index/getFTSEList'
    }),
    listIndex () {
      return [...this.listIndexGetters, 'FTSE SET'] || []
    },
    topRanking () {
      return this.topRankingGetters || []
    },
    FTSEStockList () {
      return (this.FTSEStock && this.FTSEStock.indices) || []
    },

    marketStatus () {
      if (!this.indexInfo.marketDateTime && !this.indexInfo.marketStatus) {
        return ''
      }
      // const dateTime = new Date(this.indexInfo.marketDateTime)
      // const time = dateTime.toLocaleTimeString('th-TH')
      const date = formatDateTimeLong(
        this.indexInfo.marketDateTime || null,
        this.$i18n.locale
      )
      if (this.tabActive === 'ftse-set') {
        return `<div class="text-black">${this.$t(
          'homePage.marketUpdate.marketStatus'
        )} &nbsp <span>${
          this.indexInfo.marketStatus
        } </span> &nbsp </div> <div>${this.$t(
          'homePage.marketUpdate.marketStatus15Delaying',
          { date }
        )}</div>`
      } else {
        return `<div class="text-black">${this.$t(
          'homePage.marketUpdate.marketStatus'
        )} &nbsp <span>${
          this.indexInfo.marketStatus
        } </span> &nbsp </div> <div>${this.$t('dateInfoLast', { date })}</div>`
      }
    },

    conditionSwichTab () {
      if (this.tabName === 'SET') {
        return true
      }
      return false
    },

    viewMoreTopten () {
      const market = this.payloadTopRanking.index
      const type = this.payloadTopRanking.rankingType
      return this.localePath(
        `/market/product/stock/top-ranking?${market ? `market=${market}#` : ''}${
          type ? `rankingType-${type}` : ''
        }`
      )
    }
  },
  beforeDestroy () {
    this.removeEvent()
  },
  async mounted () {
    if (this.tabName === 'mai') {
      await this.getInitData()
      this.$emit('awaitContentLoaded', false)
    }
    await this.init()
    this.$nextTick(() => {
      this.registerEvent()
      this.responsive()
    })
  },

  methods: {
    ...mapActions({
      getListIndex: 'home/set-mai/getListIndex',
      getIndexInfo: 'home/set-mai/getIndexInfo',
      getIndexChartData: 'home/set-mai/getIndexChartData',
      getTopRanking: 'home/set-mai/getTopRanking',
      getFTSEList: 'market/index/getFTSEList'
    }),
    handleClickViewMore () {
      this.$refs.componentRanking.handleClickViewMore()
    },
    async getInitData () {
      this.payloadSET.index = this.tabName
      this.payloadTopRanking.index = this.tabName
      this.titleRankType = this.listTopRank[0].title
      await Promise.allSettled([
        this.getListIndex(),
        this.getIndexInfo(this.payloadSET)
        // this.getIndexChartData(this.payloadSET),
      ])
      // if (this.listIndexGetters && this.listIndexGetters.length > 0) {
      //   this.listIndex = [...this.listIndexGetters, 'FTSE SET']
      // }
      const lowerTabName = this.payloadSET.index.toLowerCase()
      if (this.indexInfoGetters && this.indexInfoGetters[lowerTabName]) {
        this.indexInfo = this.indexInfoGetters[lowerTabName]
      }
      await this.setInitTopTenData()
    },
    async setInitTopTenData () {
      await this.getTopRanking(this.payloadTopRanking)
      // .then(() => {
      //   if (this.topRankingGetters && this.topRankingGetters.stocks) {
      //     this.topRanking = this.topRankingGetters
      //   }
      // })
    },
    async init () {
      // this.loading = false
      // this.loadingChart = true
      // await Promise.allSettled([
      //   this.getListIndex(),
      //   this.getIndexInfo(this.payloadSET),
      //   this.getIndexChartData(this.payloadSET),
      //   this.getTopRanking(this.payloadTopRanking)
      // ])
      await this.getIndexChartData(this.payloadSET)
      // this.loading = true
      // this.loadingChart = false
      // this.indexChartData = this.indexChartDataGetters
      if (this.indexChartDataGetters) {
        this.indexChartData = {
          ...this.indexChartDataGetters,
          quotations: this.indexChartDataGetters.quotations.map((item) => {
            if (item.price !== null) {
              return {
                ...item,
                price: item.price
              }
            } else {
              return {
                ...item
              }
            }
          })
        }
      }
    },

    async handleSelectSetindex (SETINDEX) {
      // add event eventtrackingAction
      // this.handleClickItem(SETINDEX)

      // this.loadingInfo = true
      this.loadingChart = true
      const lowerTabName = SETINDEX.toLowerCase()

      this.payloadSET.index = SETINDEX
      this.setIndexFilterActive = lowerTabName
      if (lowerTabName === 'ftse set') {
        this.tabActive = 'ftse-set'

        const payload = { type: '' }

        await this.getFTSEList(payload)

        this.FTSElistIndex = this.FTSEStockList

        if (this.FTSEStock) {
          this.indexInfo = {
            ...this.FTSEStock,
            marketDateTime: this.FTSEStock.datetime
          }
        }
      } else {
        this.tabActive = 'set'

        const payload = this.payloadSET

        this.payloadTopRanking.index = SETINDEX

        await Promise.allSettled([
          this.getIndexInfo(payload),
          this.getIndexChartData(payload)
        ])

        this.indexInfo = this.indexInfoGetters[lowerTabName]
        // this.indexChartData = this.indexChartDataGetters
        if (this.indexChartDataGetters) {
          this.indexChartData = {
            ...this.indexChartDataGetters,
            quotations: this.indexChartDataGetters.quotations.map((item) => {
              if (item.price !== null) {
                return {
                  ...item,
                  price: item.price
                }
              } else {
                return {
                  ...item
                }
              }
            })
          }
        }
      }
      // this.loadingInfo = false
      this.loadingChart = false
    },
    async handleSelectTopRanking (rankingType, title) {
      this.isClient = process.client
      // collapse
      // this.topRanking = []
      this.titleRankType = title
      this.heightCollapse = 'auto'
      this.loadingRanking = true
      this.payloadTopRanking.rankingType = rankingType
      const payload = this.payloadTopRanking
      await this.getTopRanking(payload)
      // this.topRanking = this.topRankingGetters
      this.topRankingStocks = this.topRanking.stocks
      this.loadingRanking = false

      if (!(window.innerWidth >= 768)) {
        const heightContent = this.$refs.collapseRanking
        if (heightContent) {
          // const heightContentHeight = this.$refs.collapseRanking.clientHeight
          // this.heightCollapse = heightContentHeight
          this.heightCollapse = 'auto'
        }
      }
    },

    handleMobileCollapse () {
      const heightContent = this.$refs.collapseRanking
      if (heightContent) {
        const heightContentHeight = this.$refs.collapseRanking.clientHeight
        if (this.heightCollapse === 'auto') {
          this.heightCollapse = heightContentHeight
        }
        setTimeout(() => {
          this.$nextTick(() => {
            if (this.heightCollapse === 0 && this.heightCollapse !== 'auto') {
              this.heightCollapse = heightContent.clientHeight
            } else {
              this.heightCollapse = 0
            }
          })
        }, 0)
      }
    },

    responsive () {
      if (window.innerWidth >= 768) {
        this.heightCollapse = 'auto'
      }
    },

    registerEvent () {
      window.addEventListener('resize', this.responsive)
    },

    removeEvent () {
      window.removeEventListener('resize', this.responsive)
    },
    clickQuote (dataQuote) {
      let name = ''
      if (this.tabName === 'SET') {
        name = 'home6'
      } else if (this.tabName === 'mai') {
        name = 'home11'
      }
      if (dataQuote && dataQuote.symbol && this.titleRankType) {
        this.$personalized.clickElement({
          name,
          optionLang: {
            type: this.titleRankType,
            quote: dataQuote.symbol
          }
        })
      }
    }
    // handleClickItem (index) {
    //   this.$personalized.clickElement({
    //     name: 'home2',
    //     optionLang: {
    //       index
    //     }
    //   })
    // }
  }
}
