import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import * as am5stock from '@amcharts/amcharts5/stock'
import am5ThemesAnimated from '@amcharts/amcharts5/themes/Animated'
import amLangTH from '@amcharts/amcharts5/locales/th_TH'

// chart license
const CHART_LICENSE_V5 = 'AM5C311284907'
am5.addLicense(CHART_LICENSE_V5)

export { am5, am5xy, am5ThemesAnimated, am5stock, amLangTH }
