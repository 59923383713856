import { render, staticRenderFns } from "./MarketUpdateStock.vue?vue&type=template&id=dc42db5c&"
import script from "./MarketUpdateStock.vue?vue&type=script&lang=js&"
export * from "./MarketUpdateStock.vue?vue&type=script&lang=js&"
import style0 from "./MarketUpdateStock.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconMostActiveValue: require('/app/components/Icon/MostActiveValue.vue').default,IconMostActiveVolume: require('/app/components/Icon/MostActiveVolume.vue').default,IconTopGainer: require('/app/components/Icon/TopGainer.vue').default,IconTopLoser: require('/app/components/Icon/TopLoser.vue').default,IconPopularSearch: require('/app/components/Icon/PopularSearch.vue').default,ButtonTabPill: require('/app/components/Button/TabPill.vue').default})
