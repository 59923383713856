//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'
export default {
  props: {
    tabName: {
      type: String,
      default: 'SET'
    }
  },
  computed: {
    ...mapGetters({
      summaryRetroGetters: 'home/set-mai/getSummarryRetro'
    })
  },
  async mounted () {
    await this.init()
  },
  methods: {
    ...mapActions({
      getSummarryRetro: 'home/set-mai/getSummarryRetro'
    }),
    async init () {
      // this.loading = true
      // await this.getSetSummarry()
      await this.getSummarryRetro({ index: this.tabName })
      // console.log('this.summarryRetroGetters', this.summarryRetroGetters)
      //   this.summarryRetro = this.summaryRetroGetters
      // this.loading = false
    }
  }
}
