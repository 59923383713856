var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'pb-3': !(_vm.payloadSET.index === 'SET' || _vm.payloadSET.index === 'mai')
  }},[(_vm.conditionSwichTab && _vm.listIndex && _vm.listIndex.length > 0)?_c('HomeMarketUpdateListIndex',{attrs:{"list-index":_vm.listIndex,"handle-select":_vm.handleSelectSetindex}}):_vm._e(),_vm._v(" "),_c('div',[_c('div',{staticClass:"row"},[_c('div',{class:_vm.payloadSET.index === 'SET' || _vm.payloadSET.index === 'mai'
            ? 'col-xl-8'
            : 'col-xl-12'},[(_vm.tabActive === 'set')?[(_vm.indexInfo)?_c('HomeMarketUpdateStatus',{class:{ 'pt-3': _vm.payloadSET.index === 'mai' },attrs:{"status":_vm.marketStatus}}):_vm._e(),_vm._v(" "),(_vm.tabName === 'SET' && _vm.setIndexFilterActive === 'set')?_c('HomeMarketUpdateChartSET',{attrs:{"data-obj":_vm.indexInfo,"data-chart":_vm.indexChartData,"market-index":_vm.setIndexFilterActive}}):(
              (_vm.tabName === 'SET' && _vm.setIndexFilterActive !== 'set') ||
                _vm.tabName === 'mai'
            )?_c('HomeMarketUpdateChartMai',{attrs:{"data-obj":_vm.indexInfo,"data-chart":_vm.indexChartData,"loading-chart":_vm.loadingChart}}):_vm._e(),_vm._v(" "),(_vm.tabName === 'SET' && _vm.setIndexFilterActive === 'set')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-7 mt-2 pe-md-0"},[_c('HomeMarketUpdateSetTRI')],1)]):_vm._e()]:(_vm.tabActive === 'ftse-set')?[(_vm.indexInfo)?_c('HomeMarketUpdateStatus',{attrs:{"status":_vm.marketStatus}}):_vm._e(),_vm._v(" "),_c('HomeMarketUpdateFTSESET',{attrs:{"ftse-list":_vm.FTSElistIndex}})]:_vm._e()],2),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.payloadSET.index === 'SET' || _vm.payloadSET.index === 'mai'),expression:"payloadSET.index === 'SET' || payloadSET.index === 'mai'"}],staticClass:"col-xl-4"},[_c('HomeMarketUpdateDaySummaryTab',{class:{
            'summary-tab-set':
              _vm.tabName === 'SET' && _vm.setIndexFilterActive === 'set'
          },attrs:{"tab-name":_vm.tabName}})],1)]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.payloadSET.index === 'SET' || _vm.payloadSET.index === 'mai'),expression:"payloadSET.index === 'SET' || payloadSET.index === 'mai'"}],staticClass:"row"},[_c('HomeMarketUpdateStock',{ref:"componentRanking",class:{
          'title-ranking-set':
            (_vm.tabName === 'SET' && _vm.setIndexFilterActive !== 'set') ||
            _vm.tabName === 'mai'
        },attrs:{"handle-select":_vm.handleSelectTopRanking,"list-top-rank":_vm.listTopRank,"market-tab":_vm.payloadSET.index}}),_vm._v(" "),_c('div',{staticClass:"home-collapse-top-ranking overflow-hidden",style:({
          height:
            _vm.heightCollapse + (typeof _vm.heightCollapse === 'number' ? 'px' : '')
        })},[(_vm.loadingRanking && _vm.isClient)?[_c('div',{staticClass:"row list-card-stock px-1"},_vm._l((5),function(num){return _c('div',{key:num,staticClass:"market-card-wrapper-item col-12 col-md",staticStyle:{"min-height":"6.8125rem"}},[_c('Placeholder',{staticClass:"h-100 w-100 ratio"})],1)}),0)]:_vm._e(),_vm._v(" "),(!_vm.loadingRanking)?[(
              (_vm.topRankingStocks && _vm.topRankingStocks.length > 0)
            )?_c('div',{ref:"collapseRanking",staticClass:"row list-card-stock px-1"},_vm._l((_vm.topRankingStocks.slice(0, 5)),function(item,index){return _c('div',{key:((_vm.payloadTopRanking.rankingType) + "-" + index),staticClass:"market-card-wrapper-item col-12 col-md"},[_c('CardStockUpdate',{attrs:{"data-obj":item,"ranking-type":_vm.payloadTopRanking.rankingType,"data-market":Object.assign({}, item,
                  {symbol: item.symbol,
                  securityType: (_vm.topRanking.securityType || '').toUpperCase(),
                  favorite: false})},on:{"clickQuote":_vm.clickQuote}})],1)}),0):_c('Message')]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{},[_c('small',{staticClass:"fs-12px"},[_c('span',{staticClass:"fw-bold"},[_vm._v(_vm._s(_vm.$t('homePage.marketUpdate.remark')))]),_vm._v(" "),_c('ol',{staticClass:"text-muted"},[(_vm.tabName === 'SET')?_c('li',{staticClass:"drx-info"},[_vm._v(_vm._s(_vm.$t('homePage.marketUpdate.drxValueAndVolume')))]):_vm._e(),_vm._v(" "),_c('li',[_vm._v(_vm._s(_vm.$t('homePage.marketUpdate.autoMatching')))])])])]),_vm._v(" "),_c('div',{staticClass:"d-none d-md-block"},[_c('ButtonViewMore',{staticClass:"fs-18px",attrs:{"url":_vm.viewMoreTopten,"title":_vm.$t('buttons.viewMore')},on:{"click":function($event){return _vm.handleClickViewMore()}}})],1)])],2),_vm._v(" "),_c('div',{staticClass:"d-md-none d-flex justify-content-center py-3"},[_c('ButtonViewMore',{staticClass:"fs-18px",attrs:{"url":_vm.viewMoreTopten,"title":_vm.$t('buttons.viewMore')},on:{"click":function($event){return _vm.handleClickViewMore()}}})],1),_vm._v(" "),_c('div',{staticClass:"\n          button-collapse-top-rank\n          mb-2\n          d-md-none d-flex\n          align-items-center\n          justify-content-center\n        "},[_c('div',{staticClass:"\n            button-collapse\n            d-flex\n            align-items-center\n            justify-content-center\n          ",class:{
            close: _vm.heightCollapse === 0 && _vm.heightCollapse !== 'auto'
          },on:{"click":_vm.handleMobileCollapse}},[_c('client-only',[_c('svg',{attrs:{"width":"14","height":"14","viewBox":"0 0 14 14","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M11.5852 9.33337L12.8333 8.08531L7.62395 2.87601L6.99992 2.27911L6.37589 2.87601L1.16659 8.08531L2.41465 9.33337L6.99992 4.7481L11.5852 9.33337Z","fill":"#1E1E21"}})])])],1)])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }