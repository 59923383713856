import { render, staticRenderFns } from "./SetNews.vue?vue&type=template&id=87fe3488&"
import script from "./SetNews.vue?vue&type=script&lang=js&"
export * from "./SetNews.vue?vue&type=script&lang=js&"
import style0 from "./SetNews.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Placeholder: require('/app/components/Placeholder/Placeholder.vue').default,PlaceholderList: require('/app/components/Placeholder/List.vue').default,Tab: require('/app/components/NavTabs/Tab.vue').default,IconBellNoti: require('/app/components/Icon/BellNoti.vue').default,Tabs: require('/app/components/NavTabs/Tabs.vue').default,HomeSetNewsItem: require('/app/components/Pages/Home/SetNews/Item.vue').default,Divider: require('/app/components/Divider.vue').default,Message: require('/app/components/Message/index.vue').default,ButtonViewMore: require('/app/components/Button/ViewMore.vue').default,TabPane: require('/app/components/NavTabs/TabPane.vue').default,TabContent: require('/app/components/NavTabs/TabContent.vue').default,ContentLazy: require('/app/components/ContentLazy.vue').default,HomeCalendarTab: require('/app/components/Pages/Home/Calendar/CalendarTab.vue').default})
