//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AccordionItem',
  props: {
    parent: {
      type: String,
      default: ''
    },
    collapseParrent: {
      type: String,
      default: ''
    },
    itemId: {
      type: String,
      default: ''
    },
    active: {
      type: Boolean,
      default: false
    },
    activeClassName: {
      type: String,
      default: 'active'
    },
    activeClassArrow: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      collapse: null
    }
  },
  computed: {
    headingId () {
      if (this.parent) {
        return `${this.parent}-heading-${this.itemId}`
      }
      return `heading-${this.itemId}`
    },
    collapseId () {
      if (this.parent) {
        return `${this.parent}-collapse-${this.itemId}`
      }
      return `collapse-${this.itemId}`
    },
    activeClass () {
      if (this.active && this.activeClassName) {
        return this.activeClassName
      }
      return ''
    }
  },
  mounted () {
    this.init()
    this.registerEvent()
  },
  beforeDestroy () {
    this.removeEvent()
    this.collapse = null
  },
  methods: {
    init () {
      const { Collapse } = require('bootstrap')
      const options = {
        toggle: false
      }
      if (this.parent) {
        options.parent = `#${this.parent}`
      }
      this.collapse = new Collapse(this.$refs.collapse, options)
    },
    registerEvent () {
      this.$refs.collapse.addEventListener('show.bs.collapse', this.handleShown)
      this.$refs.collapse.addEventListener(
        'hide.bs.collapse',
        this.handleHidden
      )
      this.$refs.collapse.addEventListener('shown.bs.collapse', () => { this.$emit('handleShown') })
      this.$refs.collapse.addEventListener('hidden.bs.collapse', () => { this.$emit('handleHidden') })
    },
    removeEvent () {
      this.$refs.collapse.removeEventListener(
        'show.bs.collapse',
        this.handleShown
      )
      this.$refs.collapse.removeEventListener(
        'hide.bs.collapse',
        this.handleHidden
      )
      this.$refs.collapse.removeEventListener('shown.bs.collapse', () => { this.$emit('handleShown') })
      this.$refs.collapse.removeEventListener('hidden.bs.collapse', () => { this.$emit('handleHidden') })
    },
    handleShown (e) {
      e.stopPropagation()
      this.$refs.accordionItem.classList.add(this.activeClassName)
    },
    handleHidden (e) {
      e.stopPropagation()
      this.$refs.accordionItem.classList.remove(this.activeClassName)
    },
    expandAndCollapseAll (collapse = 'default') {
      if (this.parent) {
        const { Collapse } = require('bootstrap')
        const triggerEl = document.querySelectorAll(
          `div[data-parent=${this.parent}] .collapse`
        )
        const triggerElShow = document.querySelectorAll(
          `div[data-parent=${this.parent}] .collapse.show`
        )
        const numPanelOpen = triggerElShow.length
        if (collapse === 'default') {
          if (numPanelOpen === 0) {
            triggerEl.forEach((el) => {
              const collapseTrigger = Collapse.getOrCreateInstance(el)
              collapseTrigger.show()
            })
            return true // isExpand All
          } else {
            triggerElShow.forEach((el) => {
              const collapseTrigger = Collapse.getOrCreateInstance(el)
              collapseTrigger.hide()
            })
            return false // isCollapse All
          }
        } else if (collapse === 'show') {
          triggerEl.forEach((el) => {
            const collapseTrigger = Collapse.getOrCreateInstance(el)
            collapseTrigger.show()
          })
          return true // isExpand All
        } else if (collapse === 'hide') {
          triggerElShow.forEach((el) => {
            const collapseTrigger = Collapse.getOrCreateInstance(el)
            collapseTrigger.hide()
          })
          return false // isCollapse All
        }
      }
    },
    isCollapseOrExpand () {
      if (this.parent) {
        const triggerElShow = document.querySelectorAll(
          `div[data-parent=${this.parent}].accordion-item.active`
        )
        const numPanelOpen = triggerElShow.length
        if (numPanelOpen === 0) {
          return false // isCollapse All
        } else {
          return true // isExpand All
        }
      } else if (this.collapseParrent) {
        const triggerElShow = document.querySelectorAll(
          `.${this.collapseParrent}.accordion-item.active`
        )
        const numPanelOpen = triggerElShow.length
        if (numPanelOpen === 0) {
          return false // isCollapse All
        } else {
          return true // isExpand All
        }
      }
    }
  }
}
