//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatDateShort } from '~/helpers/dateTime'
import { convertToMillionFormat } from '~/helpers/number'
export default {
  name: 'DaySummaryTab',
  props: {
    tabName: {
      type: String,
      default: 'SET'
    }
  },
  data () {
    return {
      loading: false,
      dataSummarry: null
    }
  },
  async fetch () {
    await this.getSetSummarry()
  },
  computed: {
    dateUpdate () {
      // return `ณ วันที่ : ${thaiShortFormat(this.dataSummarry.asOfDate)}`
      return this.$t('dateInfo', {
        date: formatDateShort(this.dataSummarry.asOfDate, this.$i18n.locale)
      })
    },
    idTap () {
      return `d-s-tab-pane-${this.tabName}`
    },
    viewMoreinvestOrType () {
      return this.localePath(
        `/market/statistics/investor-type?market=${this.tabName}`
      )
    }
  },
  methods: {
    async getSetSummarry () {
      await this.$axios
        .get(`api/set/market/${this.tabName}/investor-type`)
        .then((res) => {
          this.dataSummarry = res.data
        })
    },
    priceFormat (price) {
      return convertToMillionFormat(price || null)
    },
    handleClickTrackingViewMore (name) {
      this.$personalized.clickElement({
        name
      })
    }
  }
}
