//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import { convertToPriceFormat, convertToMillionFormat, formatNumberInteger, formatNumberPercent, formatNumber } from '~/helpers/number'
import { integerStyle } from '@/helpers/index'
export default {
  name: 'CardStockUpdate',
  props: {
    dataObj: {
      type: Object,
      default: () => {}
    },
    dataMarket: {
      type: Object,
      default: () => {}
    },
    rankingType: {
      type: String,
      default: 'mostActiveValue'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      rankingChartData: null,
      // dataItem: this.dataObj,
      buttonQuote: this.dataMarket
    }
  },
  computed: {
    ...mapGetters({
      rankingChartDataGetters: 'home/set-mai/getRankingChartData'
    }),
    change () {
      return `${this.numberFormatInt(this.dataObj.change)} (${this.numberFormatPercent(
        this.dataObj.percentChange
      )})`
    },
    textVolumeAndValue () {
      if (this.rankingType === 'mostActiveValue') {
        return this.$t('homePage.marketUpdate.valueBaht') // "มูลค่า ('000 บาท)"
      } else {
        return this.$t('homePage.marketUpdate.volumeStock') // 'ปริมาณ (หุ้น)'
      }
    }
  },
  // watch: {
  //   dataObj () {
  //     console.log('watcxh')
  //     if (this.dataObj) {
  //       this.rankingChartData = null
  //       this.dataObj = this.dataObj
  //       this.buttonQuote = this.dataMarket
  //       this.init()
  //     }
  //   }
  // },
  mounted () {
    this.init()
    // this.rankingChartData = this.rankingChartDataGetters
  },
  methods: {
    convertToPriceFormat,
    formatNumber,
    ...mapActions({
      getRankingChartData: 'home/set-mai/getRankingChartData'
    }),
    integerStyle,
    async init () {
      if (this.dataObj.symbol) {
        await this.getRankingChartData({ symbol: this.dataObj.symbol })
        this.rankingChartData = cloneDeep(this.rankingChartDataGetters)
      }
    },
    numberFormat (number) {
      return formatNumber(number)
    },
    numberFormatInt (number) {
      return formatNumberInteger(number)
    },
    numberFormatPercent (number) {
      return formatNumberPercent(number)
    },
    numberFormatMillion (number) {
      return convertToMillionFormat(number)
    }
  }
}
