import { render, staticRenderFns } from "./CardTFEX.vue?vue&type=template&id=6791239c&"
import script from "./CardTFEX.vue?vue&type=script&lang=js&"
export * from "./CardTFEX.vue?vue&type=script&lang=js&"
import style0 from "./CardTFEX.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ButtonSymbolLink: require('/app/components/Button/ButtonSymbolLink.vue').default,Charts2MicroLine: require('/app/components/Charts2/MicroLine.vue').default,IconTriangle: require('/app/components/Icon/Triangle.vue').default})
