//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formatNumber, formatNumberInteger } from '@/helpers/number'
import { integerStyle } from '@/helpers/index'
export default {
  name: 'HomeTFEXTableTFEX',
  props: {
    category: {
      type: String,
      default: ''
    },
    fields: {
      type: Array,
      default: Array
    },
    items: {
      type: Array,
      default: Array
    }
  },
  data () {
    return {}
  },
  methods: {
    formatNumber,
    formatNumberInteger,
    integerStyle
  }
}
