//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { customAlphabet } from 'nanoid'
const nanoid = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyz', 10)
export default {
  name: 'Table',
  props: {
    responsive: {
      type: Boolean,
      default: true
    },
    sortBy: {
      type: String,
      default: null
    },
    sortDesc: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    },
    selectable: {
      type: Boolean,
      default: false
    },
    noCollapse: {
      type: Boolean,
      default: false
    },
    perPage: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    },
    tableHover: {
      type: Boolean,
      default: true
    },
    showEmpty: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      tableId: ''
    }
  },
  mounted () {
    this.tableId = `table-${nanoid()}`
  },
  methods: {
    sortChange (sortItem) {
      this.$nextTick(() => {
        this.resetSortStyle()
        if (sortItem && sortItem.sortBy) {
          const sortElement = document.querySelector(
            `#${this.tableId} [aria-sort="ascending"],[aria-sort="descending"]`
          )
          if (sortElement) {
            const colindex = sortElement.getAttribute('aria-colindex')
            const tdColindexElement = document.querySelectorAll(
              `#${this.tableId} td[aria-colindex="${colindex}"]`
            )
            if (tdColindexElement) {
              tdColindexElement.forEach((ele) => {
                ele.classList.add('sortby-highlight')
              })
            }
          }
        }
        this.$emit('sort-changed', sortItem)
      })
    },
    resetSortStyle () {
      const sortHighlightElement = document.querySelectorAll(
        `#${this.tableId} .sortby-highlight`
      )
      if (sortHighlightElement) {
        sortHighlightElement.forEach((ele) => {
          ele.classList.remove('sortby-highlight')
        })
      }
    }
  }
}
