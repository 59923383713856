import { render, staticRenderFns } from "./DaySummaryTab.vue?vue&type=template&id=7d1d8b6a&"
import script from "./DaySummaryTab.vue?vue&type=script&lang=js&"
export * from "./DaySummaryTab.vue?vue&type=script&lang=js&"
import style0 from "./DaySummaryTab.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Tab: require('/app/components/NavTabs/Tab.vue').default,Tabs: require('/app/components/NavTabs/Tabs.vue').default,HomeMarketUpdateTableDaySummary: require('/app/components/Pages/Home/MarketUpdate/TableDaySummary.vue').default,Message: require('/app/components/Message/index.vue').default,ButtonViewMore: require('/app/components/Button/ViewMore.vue').default,TabPane: require('/app/components/NavTabs/TabPane.vue').default,HomeMarketUpdateDaySummaryTabChart: require('/app/components/Pages/Home/MarketUpdate/DaySummaryTabChart.vue').default,ContentLazy: require('/app/components/ContentLazy.vue').default,TabContent: require('/app/components/NavTabs/TabContent.vue').default})
