//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  convertToPriceFormat,
  convertToMillionFormat,
  formatNumberInteger,
  formatNumberPercent,
  formatVolume
} from '~/helpers/number'
import { integerStyle } from '~/helpers/index'
export default {
  name: 'MarketUpdateChartSET',
  props: {
    dataObj: {
      type: Object,
      default: Object
    },
    dataChart: {
      type: Object,
      default: () => {}
    },
    marketIndex: {
      type: String,
      default: 'set'
    }
  },
  data () {
    return {
      setData: []
      // fieldsIndex: [
      //   {
      //     label: this.$t('homePage.marketUpdate.index'),
      //     key: 'symbol',
      //     thClass: 'text-start mw-82 px-1',
      //     tdClass: 'title-symbol heading1-font-family fs-20px px-1 mw-82'
      //   },
      //   {
      //     label: this.$t('homePage.marketUpdate.last'),
      //     key: 'last',
      //     thClass: 'text-end mw-100 px-1',
      //     tdClass: 'text-end text-nowrap fs-20px px-1 mw-100 text-deep-gray'
      //   },
      //   {
      //     label: this.$t('homePage.marketUpdate.change'),
      //     key: 'change',
      //     thClass: 'text-end px-1',
      //     tdClass: (value, key, item) => {
      //       return 'text-end text-nowrap fs-20px px-1 ' + integerStyle(value)
      //     },
      //     sortable: false
      //   },
      //   {
      //     label: this.$t('homePage.marketUpdate.volumeThousand'),
      //     key: 'volume',
      //     thClass: 'text-end px-1',
      //     tdClass: 'text-end text-nowrap fs-20px px-1 text-deep-gray'
      //   },
      //   {
      //     label: this.$t('homePage.marketUpdate.valueMbath'),
      //     key: 'value',
      //     thClass: 'text-end px-1',
      //     tdClass: 'text-end text-nowrap fs-20px px-1 text-deep-gray'
      //   }
      // ]
    }
  },
  async fetch () {
    await this.getMarketSummary()
  },
  computed: {
    isOver10Items () {
      return this.setData?.length > 10
    },
    change () {
      if (this.dataObj && this.dataObj.change && this.dataObj.percentChange) {
        return `${this.numberFormatInt(
          this.dataObj.change
        )} (${this.numberFormatPercent(this.dataObj.percentChange)})`
      }

      return '-'
    },
    fieldsIndex () {
      return [
        {
          label: this.$t('homePage.marketUpdate.index'),
          key: 'symbol',
          thClass: 'text-start mw-82 px-1',
          tdClass: 'title-symbol heading1-font-family fs-20px px-1 mw-82'
        },
        {
          label: this.$t('homePage.marketUpdate.last'),
          key: 'last',
          thClass: 'text-end mw-100 px-1',
          tdClass: 'text-end text-nowrap fs-20px px-1 mw-100 text-deep-gray'
        },
        {
          label: this.$t('homePage.marketUpdate.change'),
          key: 'change',
          thClass: 'text-end px-1',
          tdClass: (value, key, item) => {
            return 'text-end text-nowrap fs-20px px-1 ' + integerStyle(value)
          },
          sortable: false
        },
        {
          label: this.$t('homePage.marketUpdate.volumeThousand'),
          key: 'volume',
          thClass: 'text-end px-1',
          tdClass: 'text-end text-nowrap fs-20px px-1 text-deep-gray'
        },
        {
          label: this.$t('homePage.marketUpdate.valueMbath'),
          key: 'value',
          thClass: 'text-end px-1',
          tdClass: 'text-end text-nowrap fs-20px px-1 text-deep-gray'
        }
      ]
    }
  },
  mounted () {
    if (this.marketIndex === 'set') {
      this.getMarketSummary()
    }
  },
  methods: {
    integerStyle,
    async getMarketSummary () {
      // this.loadingTable = true
      await this.$axios
        .$get('api/set/index/info/list?type=INDEX')
        .then((res) => {
          if (res && res.indexIndustrySectors.length) {
            this.setData = res.indexIndustrySectors.filter(
              index => index.symbol !== 'mai'
            )
          } else {
            this.setData = []
          }
          // this.loadingTable = false
        })
        .catch(() => {
          this.setData = []
          // this.loadingTable = false
        })
    },
    routePathOverview (symbol) {
      if (symbol) {
        const slug = symbol.toLowerCase()
        const url = this.localePath({
          name: 'market-index-slug-overview',
          params: { slug }
        })
        return url || ''
      }
    },
    formatVolume,
    numberFormat (number) {
      return convertToPriceFormat(number)
    },
    numberFormatMillion (number) {
      return convertToMillionFormat(number)
    },
    numberFormatInt (number) {
      return formatNumberInteger(number)
    },
    numberFormatPercent (number) {
      return formatNumberPercent(number)
    },
    handleClickTracking (index) {
      if (index === 'mai') {
        this.$personalized.clickElement({
          name: 'home7',
          optionLang: {
            index
          }
        })
      } else {
        this.$personalized.clickElement({
          name: 'home2',
          optionLang: {
            index
          }
        })
      }
    }
  }
}
