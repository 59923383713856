//
//
//
//
//
//

export default {
  name: 'Accordion',
  props: {
    flush: {
      type: Boolean,
      default: false
    }
  },
  mounted () {},
  methods: {
  }
}
