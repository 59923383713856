//
//
//
//
//

export default {
  name: 'Charts2MicroLine',
  props: {
    dataObj: {
      type: Object,
      default: Object
    }
  },
  data () {
    return {
      chart: null,
      minPrice: null,
      maxPrice: null,
      loading: true
    }
  },
  watch: {
    dataObj (chartData) {
      this.clearChart()
    }
  },
  mounted () {
    setTimeout(() => {
      this.loading = false
      this.$nextTick(() => {
        this.init()
      })
    }, 500)
  },
  beforeDestroy () {
    this.clearChart()
  },
  methods: {
    clearChart () {
      if (this.chart) {
        this.chart.dispose()
        this.chart = null
      }
    },
    init () {
      const {
        am5,
        am5xy,
        // am5ThemesAnimated,
        amLangTH
      } = require('~/plugins/amChart5')

      if (!this.$refs.chartdiv) {
        return
      }

      const root = am5.Root.new(this.$refs.chartdiv)

      // root.setThemes([am5ThemesAnimated.new(root)])

      const prior = this.dataObj && this.dataObj.prior

      const data =
        (this.dataObj &&
          this.dataObj.quotations &&
          this.dataObj.quotations
            .map((item) => {
              return {
                ...item,
                datetime: new Date(item.datetime).getTime()
              }
            })
            .sort(
              (a, b) => new Date(a.datetime) - new Date(b.datetime)
            )) ||
        []

      this.minPrice =
      this.dataObj &&
      this.dataObj.quotations &&
      this.dataObj.quotations.reduce((prev, curr) => {
        return (curr.price != null && curr.price < prev) ? curr.price : prev
      }, Infinity)

      this.maxPrice =
      this.dataObj &&
      this.dataObj.quotations &&
      this.dataObj.quotations.reduce((prev, curr) => {
        return (curr.price != null && curr.price > prev) ? curr.price : prev
      }, -Infinity)

      // root.autoResize = false

      if (this.$i18n.locale === 'th') {
        root.locale = amLangTH
      }

      const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          paddingRight: 0,
          paddingLeft: 0,
          paddingBottom: 0,
          paddingTop: 0
        })
      )

      chart.zoomOutButton.set('forceHidden', true)

      // chart.root.dom.style.height = '35'

      // <------------------ xAxis -----------> //

      const xRenderer = am5xy.AxisRendererX.new(root, {})

      xRenderer.grid.template.setAll({
        visible: false
      })
      xRenderer.labels.template.setAll({
        visible: false
      })

      const xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          startLocation: 0.5,
          endLocation: 0.7,

          baseInterval: {
            timeUnit: 'minute',
            count: 1
          },

          renderer: xRenderer
        })
      )
      xAxis.set(
        'tooltip',
        am5.Tooltip.new(root, {
          forceHidden: true
        })
      )

      // <------------------ yAxis -----------> //

      const yRenderer = am5xy.AxisRendererY.new(root, {})
      yRenderer.grid.template.setAll({
        visible: false
      })

      yRenderer.labels.template.setAll({
        visible: false,
        minPosition: 0.05,
        maxPosition: 0.95
      })

      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          // interpolationDuration: 500,
          // rangeChangeDuration: 500,
          baseValue: prior,
          min: this.minPrice,
          max: this.maxPrice,
          strictMinMax: true,
          renderer: yRenderer
        })
      )

      yAxis.set(
        'tooltip',
        am5.Tooltip.new(root, {
          forceHidden: true
        })
      )

      // <------------------ series -----------> //

      const series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: 'Price',
          xAxis,
          yAxis,
          valueXField: 'datetime',
          valueYField: 'price',
          minHeight: 30,
          stroke: am5.color('#17AF54')
        })
      )

      series.fills.template.setAll({
        visible: true,
        opacity: 1
      })

      series.strokes.template.setAll({
        strokeWidth: 1
      })

      series.fills.template.set(
        'fillGradient',
        am5.LinearGradient.new(root, {
          stops: [
            {
              color: am5.color('#17AF54'),
              opacity: 0.8
            },
            {
              color: am5.color('#17AF54'),
              opacity: 0.6
            },
            {
              color: am5.color('#17AF54'),
              opacity: 0.4
            },
            {
              color: am5.color('#17AF54'),
              opacity: 0.2
            }
          ],
          rotation: 0
        })
      )

      series.data.setAll(data)
      // series.appear(1000)

      // <------------------ range -----------> //

      const rangeDataItem = yAxis.makeDataItem({
        value: prior,
        affectsMinMax: true
      })

      const rangePrior = yAxis.createAxisRange(rangeDataItem)

      rangePrior.get('grid').setAll({
        stroke: am5.color('#396478'),
        strokeWidth: 1,
        strokeOpacity: 1,
        strokeDasharray: [2, 2],
        visible: true
      })

      // rangePrior.fills.template.setAll({
      //   visible: true,
      //   opacity: 1
      // })

      // rangePrior.get('axisFill').setAll({
      //   stroke: am5.color('#396478'),
      //   strokeWidth: 1,
      //   strokeOpacity: 1,
      //   strokeDasharray: [2, 2],
      //   visible: true
      // })

      // rangeDataItem.get('grid').setAll({
      //   stroke: am5.color('#396478'),
      //   strokeWidth: 1,
      //   strokeOpacity: 1,
      //   strokeDasharray: [2, 2],
      //   visible: true
      // })

      // rangeDataItem.get('label').setAll({
      //   inside: true
      // })

      const rangeDataItem2 = yAxis.makeDataItem({
        value: prior,
        endValue: 0
      })

      const rangePrior2 = series.createAxisRange(rangeDataItem2)

      rangePrior2.strokes.template.setAll({
        stroke: am5.color('#EC3E3E'),
        strokeWidth: 1
      })

      rangePrior2.fills.template.setAll({
        visible: true,
        fillOpacity: 1
      })

      rangePrior2.fills.template.set(
        'fillGradient',
        am5.LinearGradient.new(root, {
          stops: [
            {
              color: am5.color('#EC3E3E'),
              opacity: 0.8
            },
            {
              color: am5.color('#EC3E3E'),
              opacity: 0.6
            },
            {
              color: am5.color('#EC3E3E'),
              opacity: 0.4
            },
            {
              color: am5.color('#EC3E3E'),
              opacity: 0.2
            }
          ],
          rotation: 0
        })
      )

      this.chart = root
    }
  }
}
