import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=1ebccf9f&scoped=true&"
import script from "./Home.vue?vue&type=script&lang=js&"
export * from "./Home.vue?vue&type=script&lang=js&"
import style0 from "./Home.vue?vue&type=style&index=0&id=1ebccf9f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ebccf9f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeIndexSet: require('/app/components/Pages/Home/IndexSet.vue').default,Banner: require('/app/components/Banner/Banner.vue').default,HomeSetNews: require('/app/components/Pages/Home/SetNews/SetNews.vue').default,ContentLazy: require('/app/components/ContentLazy.vue').default,HomeInFocus: require('/app/components/Pages/Home/InFocus/index.vue').default,HomeIPO: require('/app/components/Pages/Home/IPO/IPO.vue').default,HomeOppday: require('/app/components/Pages/Home/Oppday/Oppday.vue').default})
