import { render, staticRenderFns } from "./IPO.vue?vue&type=template&id=2d3128e8&"
import script from "./IPO.vue?vue&type=script&lang=js&"
export * from "./IPO.vue?vue&type=script&lang=js&"
import style0 from "./IPO.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Tab: require('/app/components/NavTabs/Tab.vue').default,Divider: require('/app/components/Divider.vue').default,Tabs: require('/app/components/NavTabs/Tabs.vue').default,PlaceholderIPOCard: require('/app/components/Placeholder/IPOCard.vue').default,HomeIPOUpcoming: require('/app/components/Pages/Home/IPO/Upcoming.vue').default,Button: require('/app/components/Button/Button.vue').default,ContentLazy: require('/app/components/ContentLazy.vue').default,TabPane: require('/app/components/NavTabs/TabPane.vue').default,HomeIPOFirstday: require('/app/components/Pages/Home/IPO/Firstday.vue').default,HomeIPORecent: require('/app/components/Pages/Home/IPO/Recent.vue').default,TabContent: require('/app/components/NavTabs/TabContent.vue').default})
