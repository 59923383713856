//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'IconBoxArrowUpRight2'
}
