//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'HomeOppday',
  data () {
    return {
      itemLiveList: []
    }
  },
  async fetch () {
    await this.getHomeOppday().then((res) => {
      // this.itemLiveList = this.liveAndUpcoming.slice(0, 7)
    })
  },
  computed: {
    ...mapGetters({
      homeIpoFirstday: 'home/ipo/getHomeIpoFirstday',
      homeIpoRecent: 'home/ipo/getHomeIpoRecent',
      homeOppdayArchive: 'home/oppday/getHomeOppdayArchive',
      homeOppdayPeriod: 'home/oppday/getHomeOppdayPeriod',
      liveAndUpcoming: 'home/oppday/getHomeOppdayLiveUpcoming'
    }),
    showOriginalOppday () {
      return false
    },
    swiper () {
      return this.$refs.oppdayLive.swiper
    },
    carouselOptions () {
      const options = {
        loop: false,
        showPagination: true,
        showNavigationOutSide: true,
        slidesPerView: 1,
        spaceBetween: 0,
        centeredSlides: true,
        grabCursor: true,
        resizeObserver: true,
        navigation: {
          nextEl: '.live-section .swiper-btn-next',
          prevEl: '.live-section .swiper-btn-prev'
        },
        pagination: {
          el: '.live-section .swiper-pagination',
          dynamicBullets: true,
          clickable: true,
          renderBullet: (index, className) => {
            return (
              '<span class="' +
              className +
              '"><div class="circle"></div></span>'
            )
          }
        }
      }
      return options
    },
    ipoExist () {
      return (this.homeIpoFirstday && this.homeIpoFirstday.length > 0) || (this.homeIpoUpcoming && this.homeIpoUpcoming.length > 0) || (this.homeIpoRecent && this.homeIpoRecent.length > 0)
    },
    oppUpcomingExist () {
      return this.liveAndUpcoming && this.liveAndUpcoming.length > 0
    },
    oppArchiveExist () {
      return this.homeOppdayArchive && this.homeOppdayArchive.length > 0
    }
  },
  methods: {
    ...mapActions({
      getHomeOppday: 'home/oppday/getHomeOppday'
    }),
    swiperInit (e) {
      setTimeout(() => {
        if (this.itemLiveList && this.itemLiveList.length <= 1) {
          e.allowTouchMove = false
        }
      }, 300)
    },
    modalVideoOpen (id) {
      this.$refs.modalVideo.modalOpen({
        videoId: id
      })
    },
    onClickViewMore () {
      this.$personalized.clickElement({
        name: this.$i18n.locale === 'th' ? 'homeOppdayViewMore' : 'homeDigitRsViewMore'
      })
    }
  }
}
