//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { convertToMillionFormat, convertToMillionChangeFormat } from '~/helpers/number'
import { integerStyle } from '@/helpers/index'

export default {
  name: 'TableDaySummary',
  props: {
    dataArray: {
      type: Array,
      default: Array
    }
  },
  computed: {
    data () {
      return {
        institution: this.dataArray.find(investors => investors.type === 'institution'),
        proprietary: this.dataArray.find(investors => investors.type === 'proprietary'),
        foreign: this.dataArray.find(investors => investors.type === 'foreign'),
        individual: this.dataArray.find(investors => investors.type === 'individual')
      }
    }
  },
  methods: {
    integerStyle,
    convertToMillionChangeFormat,
    priceFormat (price) {
      return convertToMillionFormat(price || null)
    }
  }
}
