//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// eslint-disable-next-line no-unused-vars
import cloneDeep from 'lodash/cloneDeep'
import { customAlphabet } from 'nanoid'
// eslint-disable-next-line no-unused-vars
const nanoid = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyz', 10)
export default {
  name: 'TableCustomField',
  props: {
    responsive: {
      type: Boolean,
      default: true
    },
    sortBy: {
      type: String,
      default: ''
    },
    filter: {
      type: String,
      default: null
    },
    filterOn: {
      type: Array,
      default: () => []
    },
    striped: {
      type: Boolean,
      default: false
    },
    sortDesc: {
      type: Boolean,
      default: false
    },
    noBorderCollapse: {
      type: Boolean,
      default: true
    },
    items: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    },
    selectable: {
      type: Boolean,
      default: true
    },
    perPage: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    },
    selectMode: {
      type: String,
      default: 'multi'
    },
    tableHover: {
      type: Boolean,
      default: true
    },
    tbodyTrClass: {
      type: [Array, Object, String, Function],
      default: () => {}
    },
    showEmpty: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    msgTitle: {
      type: String,
      default: null
    },
    msgTitleSearch: {
      type: Boolean,
      default: false
    },
    placeHolderLength: {
      type: Number,
      default: 10
    }
  },

  data () {
    return {
      sortByField: '',
      tableId: '',
      itemsList: [],
      itemsListSelected: [],
      itemsListSelectedIndex: [],
      defaultItemsPlaceHolder: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
      sortIconNeutral:
        "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e",
      sortIconDesc:
        "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e",
      sortIconAsc:
        "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e"
    }
  },
  computed: {
    itemsPlaceHolder () {
      const items = this.items ?? []
      if (items.length === 0) {
        return this.defaultItemsPlaceHolder.slice(0, this.placeHolderLength)
      }
      return items?.slice(0, this.placeHolderLength).map(() => {
        return {}
      })
    }
  },

  watch: {
    items (items) {
      this.itemsList = cloneDeep(
        items?.map((item, index) => ({
          ...item,
          id: nanoid(),
          indexSelected: index,
          selected: 'false'
        })) || []
      )
      if (this.sortByField) {
        this.sortTable(this.itemsList, this.sortBy, this.sortDesc)
        this.sortByField = ''
      }
    },

    currentPage (currentPage) {
      if (currentPage) {
        setTimeout(() => {
          this.autoSelectedTable()
        }, 100)
      }
    },

    perPage (perPage) {
      if (perPage) {
        setTimeout(() => {
          this.autoSelectedTable()
        }, 100)
      }
    }
  },
  created () {
    this.sortByField = this.sortBy
    this.tableId = `table-${nanoid()}`
    this.itemsList = cloneDeep(
      this.items?.map((item, index) => ({
        ...item,
        id: nanoid(),
        indexSelected: index,
        selected: 'false'
      })) || []
    )
  },
  mounted () {
    // this.sortByField = this.sortBy
    // this.tableId = `table-${nanoid()}`
    // this.itemsList = cloneDeep(
    //   this.items?.map((item, index) => ({
    //     ...item,
    //     id: nanoid(),
    //     indexSelected: index,
    //     selected: 'false'
    //   })) || []
    // )
  },

  methods: {
    sortCompare (
      aRow,
      bRow,
      key,
      sortDesc,
      formatter,
      compareOptions,
      compareLocale
    ) {
      // if (key === 'moneynessStatus') {
      //   return 0
      // }
      // const regex =
      //   /(?=.*?\d)^\$?((^[-+]?[0-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,})?$/
      // const a =
      //   (aRow[key] && aRow[key] !== '-') || (typeof aRow[key] === 'number' && aRow[key] >= 0)
      //     ? regex.test(aRow[key])
      //       ? Number(
      //         typeof aRow[key] === 'string'
      //           ? aRow[key].split(',').join('')
      //           : aRow[key]
      //       )
      //       : aRow[key]
      //     : regex.test(aRow[key]) || regex.test(bRow[key])
      //       ? -0.000001
      //       : aRow[key]
      // const b =
      //   (bRow[key] && bRow[key] !== '-') || (typeof bRow[key] === 'number' && bRow[key] >= 0)
      //     ? regex.test(bRow[key])
      //       ? Number(
      //         typeof bRow[key] === 'string'
      //           ? bRow[key].split(',').join('')
      //           : bRow[key]
      //       )
      //       : bRow[key]
      //     : regex.test(aRow[key]) || regex.test(bRow[key])
      //       ? -0.000001
      //       : bRow[key]
      // if (
      //   (typeof a === 'number' && typeof b === 'number') ||
      //   (a instanceof Date && b instanceof Date)
      // ) {
      //   // If both compared fields are native numbers or both are native dates
      //   return a < b ? -1 : a > b ? 1 : 0
      // } else if (this.toString(a) === '') {
      //   return 1
      // } else if (this.toString(b) === '') {
      //   return -1
      // } else {
      //   // Otherwise stringify the field data and use String.prototype.localeCompare
      //   return this.toString(a).localeCompare(
      //     this.toString(b),
      //     compareLocale,
      //     compareOptions
      //   )
      // }
    },

    sortTable (itemsList, col, ascending) {
      if (col === 'moneynessStatus') {
        return 0
      }
      const regex =
        /(?=.*?\d)^\$?((^[-+]?[0-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,})?$/

      itemsList.sort((aRow, bRow) => {
        const a =
          (aRow[col] && aRow[col] !== '-') ||
          (typeof aRow[col] === 'number' && aRow[col] >= 0)
            ? regex.test(aRow[col])
              ? Number(
                typeof aRow[col] === 'string'
                  ? aRow[col].split(',').join('')
                  : aRow[col]
              )
              : aRow[col]
            : regex.test(aRow[col]) || regex.test(bRow[col])
              ? -0.000001
              : aRow[col]

        const b =
          (bRow[col] && bRow[col] !== '-') ||
          (typeof bRow[col] === 'number' && bRow[col] >= 0)
            ? regex.test(bRow[col])
              ? Number(
                typeof bRow[col] === 'string'
                  ? bRow[col].split(',').join('')
                  : bRow[col]
              )
              : bRow[col]
            : regex.test(aRow[col]) || regex.test(bRow[col])
              ? -0.000001
              : bRow[col]

        let pattern = 0

        if (
          (typeof a === 'number' && typeof b === 'number') ||
          (a instanceof Date && b instanceof Date)
        ) {
          if (a > b && a !== '-' && b !== '-') {
            pattern = ascending ? -1 : 1
          } else if (a < b && a !== '-' && b !== '-') {
            pattern = ascending ? 1 : -1
          } else {
            pattern = 0
          }
        } else if (a === b) {
          pattern = 0
        } else if (this.toString(a) === '') {
          pattern = 1
        } else if (this.toString(b) === '') {
          pattern = -1
        } else if (a > b && a !== '-' && b !== '-') {
          pattern = ascending ? -1 : 1
        } else if (a < b && a !== '-' && b !== '-') {
          pattern = ascending ? 1 : -1
        } else {
          pattern = this.toString(a).localeCompare(
            this.toString(b),
            undefined,
            {
              usage: 'sort'
            }
          )
        }
        return pattern
      })
    },

    toString (value) {
      if (value === null || typeof value === 'undefined') {
        return ''
      } else if (value instanceof Object) {
        return Object.keys(value)
          .sort()
          .map(key => this.toString(value[key]))
          .join(' ')
      } else {
        return String(value)
      }
    },

    sortChange (sortItem) {
      this.$nextTick(() => {
        this.resetSortStyle()
        if (sortItem && sortItem.sortBy) {
          this.sortTable(this.itemsList, sortItem.sortBy, sortItem.sortDesc)

          this.$emit('sort-changed', sortItem)

          const sortElement = document.querySelector(
            `#${this.tableId} [aria-sort="ascending"],[aria-sort="descending"]`
          )
          if (sortElement) {
            const colindex = sortElement.getAttribute('aria-colindex')
            const tdColindexElement = document.querySelectorAll(
              `#${this.tableId} td[aria-colindex="${colindex}"]`
            )
            if (tdColindexElement) {
              setTimeout(() => {
                tdColindexElement.forEach((ele) => {
                  ele.classList.add('sortby-highlight')
                })
              }, 100)
            }
          }
        }
      })
      setTimeout(() => {
        this.autoSelectedTable()
      }, 100)
    },

    resetSortStyle () {
      const sortHighlightElement = document.querySelectorAll(
        `#${this.tableId} .sortby-highlight`
      )
      if (sortHighlightElement) {
        sortHighlightElement.forEach((ele) => {
          ele.classList.remove('sortby-highlight')
        })
      }
    },

    rowAttribute (item, type) {
      if (!item || type !== 'row') {
        return
      }
      return { indexSelected: item.indexSelected }
    },

    rowClicked (item, index, event) {
      const target = event && event.target
      if ((target && target.tagName === 'A') || (target && target.parentNode && target.parentNode.tagName === 'A')) {
        return
      }
      const indexSelected = item.indexSelected
      if (this.itemsListSelectedIndex.includes(indexSelected)) {
        this.itemsListSelectedIndex.splice(
          this.itemsListSelectedIndex.indexOf(indexSelected),
          1
        )
      } else if (this.selectMode === 'multi') {
        this.itemsListSelectedIndex = [
          ...new Set([...this.itemsListSelectedIndex, item.indexSelected])
        ]
      } else {
        this.itemsListSelectedIndex = [item.indexSelected]
      }
      this.itemsListSelected = this.itemsList.filter(item =>
        this.itemsListSelectedIndex.includes(item.indexSelected)
      )
      setTimeout(() => {
        this.autoSelectedTable()
      }, 100)
    },

    rowSelected (items, a, b) {
      this.$emit('row-selected', this.itemsListSelected)
    },

    initSelectable () {
      document
        .querySelectorAll(`#${this.tableId} > tbody > tr:not(.header)`)
        .forEach((tr) => {
          tr.setAttribute('aria-selected', false)
          tr.classList.remove('b-table-row-selected', 'table-active')
        })
    },

    autoSelectedTable () {
      if (this.selectable) {
        this.initSelectable()
        this.itemsListSelectedIndex.forEach((indexSelected) => {
          const trSelected = document.querySelector(
            `#${this.tableId} [indexSelected='${indexSelected}']`
          )
          if (trSelected) {
            trSelected.setAttribute('aria-selected', true)
            trSelected.classList.add('b-table-row-selected', 'table-active')
          }
        })
      }
    }
  }
}
