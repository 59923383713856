//
//
//
//
//
//
//
//

export default {
  name: 'PlaceholderList',
  props: {
    animation: {
      type: Boolean,
      default: false
    }
  }
}
