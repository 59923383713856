var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('client-only',[_c('div',{staticClass:"table-simple-main"},[_c('b-table',{attrs:{"id":_vm.tableId,"items":_vm.items,"fields":_vm.fields,"sort-by":_vm.items && _vm.items.length > 0 ? _vm.sortBy : '',"sort-desc":_vm.items && _vm.items.length > 0 ? _vm.sortDesc : '',"responsive":_vm.responsive,"per-page":_vm.perPage,"current-page":_vm.currentPage,"selectable":_vm.selectable,"no-border-collapse":_vm.noCollapse,"select-mode":"multi","table-class":'table-simple table-custom-field--cnc ' + (_vm.tableHover ? 'table-hover-underline' : ''),"show-empty":_vm.showEmpty},on:{"sort-changed":_vm.sortChange,"row-selected":function($event){return _vm.$emit('row-selected', $event)}},scopedSlots:_vm._u([{key:"thead-top",fn:function(ref){
var fields = ref.fields;
var columns = ref.columns;
return [_vm._t("thead-top",null,{"fields":fields,"columns":columns})]}},_vm._l((_vm.fields),function(fieldItem,index){return {key:("head(" + (fieldItem.key) + ")"),fn:function(ref){
var field = ref.field;
return [_vm._t(("head(" + (fieldItem.key) + ")"),function(){return [_vm._v("\n          "+_vm._s(field.label)+"\n        ")]},{"field":field,"index":index}),_vm._v(" "),_c('span',{key:index,staticClass:"aria-sort-highlight-dot"})]}}}),_vm._l((_vm.fields),function(field,indexs){return {key:("cell(" + (field.key) + ")"),fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._t(("cell(" + (field.key) + ")"),null,{"item":item,"index":index})]}}}),{key:"empty",fn:function(){return [_c('Message',{staticClass:"border-0"})]},proxy:true}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }