import { render, staticRenderFns } from "./VideoPlayer.vue?vue&type=template&id=0ab262b0&"
import script from "./VideoPlayer.vue?vue&type=script&lang=js&"
export * from "./VideoPlayer.vue?vue&type=script&lang=js&"
import style0 from "./VideoPlayer.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Placeholder: require('/app/components/Placeholder/Placeholder.vue').default,VideoStatusLive: require('/app/components/Pages/Video/StatusLive.vue').default,Picture: require('/app/components/Picture/Picture.vue').default,Picture: require('/app/components/Picture/Picture.vue').default,IconPlayVideo: require('/app/components/Icon/PlayVideo.vue').default,Button: require('/app/components/Button/Button.vue').default,Card: require('/app/components/Card/Card.vue').default})
