//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'
import { integerStyle } from '@/helpers/index'
import { formatDateTimeLong } from '@/helpers/dateTime'
import {
  formatNumber,
  formatNumberInteger,
  formatNumberPercent
} from '@/helpers/number'
export default {
  name: 'IndexSet',
  data () {
    return {
      // set: {
      //   name: 'SET',
      //   value: numberWithCommas(1566.25),
      //   percent: '+12.14 (+0.79%)'
      // },
      // mai: {
      //   name: 'mai',
      //   value: convertToPriceFormat(496.31),
      //   percent: '-2.26 (-0.45%)'
      // },
      awaitContentMAILoaded: true,
      awaitContentTFEXLoaded: true,
      isServer: process.server,
      tfex: {
        name: 'TFEX',
        oi: null,
        volume: null
      },
      // set: {
      //   querySymbol: 'SET'
      // },
      // mai: {
      //   querySymbol: 'mai'
      // },
      // tfex: null,
      loading: true,
      tabName: 'SET',
      tfexData: {},
      currentTab: 'SET'
      // maiLoaded: false
    }
  },
  async fetch () {
    await this.init()
  },
  computed: {
    ...mapGetters({
      tempIndexInfoGetters: 'home/set-mai/getTempIndexInfo'
    }),
    set () {
      return (this.tempIndexInfoGetters.set || {
        querySymbol: 'SET'
      })
    },
    mai () {
      return (this.tempIndexInfoGetters.mai || {
        querySymbol: 'mai'
      })
    },
    tfexInfo () {
      return this.tfex
    }
  },
  // mounted () {
  //   setTimeout(() => {
  //     this.loading = false
  //   })
  //   this.init()
  // },

  methods: {
    integerStyle,
    formatNumber,
    formatDateTimeLong,
    formatNumberInteger,
    formatNumberPercent,

    ...mapActions({
      getIndexInfo: 'home/set-mai/getIndexInfo'
    }),

    async init () {
      await Promise.allSettled([
        this.getIndexInfo({ index: 'set' }),
        this.getIndexInfo({ index: 'mai' })
      ])
      await this.getTFEXMarketSummary()
      // await this.getIndexInfo({ index: 'set' }).then(() => {
      //   this.set = this.tempIndexInfoGetters.set || null
      // })
      // await this.getIndexInfo({ index: 'mai' }).then(() => {
      //   this.mai = this.tempIndexInfoGetters.mai || null
      // })
      // await this.getTFEXMarketSummary()
    },

    // onSetMAI () {
    //   // this.tabName = tab
    //   this.$nextTick(() => {
    //     setTimeout(async () => {
    //       if (this.maiLoaded === false && this.$refs.homeMarketMai) {
    //         await this.$refs.homeMarketMai.getInitData()
    //         await this.$refs.homeMarketMai.init()
    //         this.maiLoaded = true
    //       }
    //     }, 500)
    //   })
    // },

    formatNumberZeroDigit (num) {
      const result = num.toLocaleString()
      return result
    },

    async getTFEXMarketSummary () {
      await this.$axios.get('api/set/tfex/market/summary').then((res) => {
        if (res.status === 200) {
          const data = res.data
          let tfexOI = data.totalFutures.oi + data.totalOptions.oi
          let tfexTotalNumber =
            data.totalFutures.volume + data.totalOptions.volume
          if (
            data.totalFutures.volume === null &&
            data.totalOptions.volume === null
          ) {
            tfexTotalNumber = null
          }
          if (data.totalFutures.oi === null && data.totalOptions.oi === null) {
            tfexOI = null
          }
          this.tfex = {
            name: 'TFEX',
            oi: tfexOI ? this.formatNumberZeroDigit(tfexOI) : null,
            volume: tfexTotalNumber ? this.formatNumberZeroDigit(tfexTotalNumber) : null
          }
          this.tfexData = {
            marketDateTime: new Date(data.marketTime),
            item_future_overview: [
              {
                name: 'Total Futures',
                volume: data.totalFutures.volume,
                oi: data.totalFutures.oi,
                segments: []
              },
              ...data.futures
            ],
            item_option_overview: [
              {
                name: 'Total Options',
                volume: formatNumber(data.totalOptions.volume, 0),
                oi: formatNumber(data.totalOptions.oi, 0)
              },
              {
                name: 'Call',
                volume: formatNumber(data.options[0].call.volume, 0),
                oi: formatNumber(data.options[0].call.oi, 0)
              },
              {
                name: 'Put',
                volume: formatNumber(data.options[0].put.volume, 0),
                oi: formatNumber(data.options[0].put.oi, 0)
              },
              {
                name: 'Put/Call',
                volume: formatNumber(data.options[0].pcRatio.volume, 2),
                oi: formatNumber(data.options[0].pcRatio.oi, 2)
              }
            ]
          }
        }
      })
    },
    selectTab (tabName) {
      this.currentTab = tabName
    }
  }
}
