//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { integerStyle } from '@/helpers/index'
import { formatNumber } from '@/helpers/number'
import { imageLoadError } from '@/helpers/image'
export default {
  name: 'HomeIPOFirstday',
  data () {
    return {
      carouselOptions: {
        loop: false,
        loopPreventsSlide: false,
        showPagination: false,
        showNavigation: true,
        showNavigationOutSide: true,
        slidesPerView: 'auto',
        spaceBetween: 20,
        centeredSlides: true,
        grabCursor: true,
        resizeObserver: true,
        navigation: {
          nextEl: '.ipo-firstday .swiper-btn-next',
          prevEl: '.ipo-firstday .swiper-btn-prev'
        },
        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: true,
          clickable: true,
          renderBullet: (index, className) => {
            return (
              '<span class="' +
              className +
              '"><div class="circle"></div></span>'
            )
          }
        },
        breakpoints: {
          320: {
            showPagination: true
          },
          992: {
            showPagination: false
          }
        }
      },
      isDesktop: true
    }
  },
  computed: {
    ...mapGetters({
      homeIpoFirstday: 'home/ipo/getHomeIpoFirstday'
    }),
    isDesktopScreen () {
      return this.isDesktop
    },
    swiper () {
      return this.$refs.ipoFirstday && this.$refs.ipoFirstday.swiper
    }
  },
  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  destroyed () {
    window.removeEventListener('resize', this.onResize, { passive: true })
  },
  methods: {
    formatNumber,
    integerStyle,
    imageLoadError,
    onResize () {
      const screenSize = window.innerWidth
      if (screenSize >= 992) {
        this.isDesktop = true
      } else {
        this.isDesktop = false
      }
    },
    modalVideoOpen (id) {
      this.$refs.modalVideo.modalOpen({
        videoId: id
      })
    },
    symbolPath (securityType, symbol) {
      let url = ''
      if (securityType === 'S') {
        url = `/${this.$i18n.locale}/market/product/stock/quote/${symbol}/price`
      } else if (securityType === 'L') {
        url = `/${this.$i18n.locale}/market/product/etf/quote/${symbol}/price`
      } else if (securityType === 'V') {
        url = `/${this.$i18n.locale}/market/product/dw/quote/${symbol}/price`
      } else if (securityType === 'X') {
        url = `/${this.$i18n.locale}/market/product/dr/quote/${symbol}/price`
      } else if (securityType === 'XF') {
        url = `/${this.$i18n.locale}/market/product/drx/quote/${symbol}/price`
      } else if (symbol && symbol.includes('-R')) {
        const regSymbol = symbol.replace(/-[r,R]/g, '').toUpperCase()
        url = `/${this.$i18n.locale}/market/product/stock/quote/${regSymbol}/major-shareholders?type=nvdr`
        url = `/${this.$i18n.locale}/market/product/stock/quote/${regSymbol}/major-shareholders?type=nvdr`
        // url = `/${this.$i18n.locale}/market/product/stock/quote/${regSymbol}/company-profile/major-shareholders?type=nvdr`
        // url = `/${this.$i18n.locale}/market/product/stock/quote/${regSymbol}/company-profile/major-shareholders?type=nvdr`
      }
      return url
    },
    eventTrackingClicked (firstdayData) {
      this.$personalized.clickElement({
        name: 'home28',
        optionLang: {
          symbol: firstdayData.symbol || ''
        }
      })
    },
    eventTrackingUrlClicked (firstdayUrlData) {
      if (firstdayUrlData.url && firstdayUrlData.url !== '#') {
        this.$personalized.clickElement({
          name: 'home29',
          optionLang: {
            url: firstdayUrlData.url
          }
        })
      }
    }
  }
}
