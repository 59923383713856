//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'
import { formatDateShort } from '~/helpers/dateTime'
const { numberWithCommas } = require('@/helpers')
export default {
  name: 'HomeTFEXMarketOverview',
  components: {
    Multiselect: () => import('vue-multiselect')
  },
  props: {
    dateTime: {
      type: Date,
      default: ''
    },
    itemFutureOverview: {
      type: Array,
      required: true
    },
    itemOptionOverview: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      futureSelected: { name: 'All Futures', value: '' },
      optionOption: [{ name: 'Call', value: 'options-call' }, { name: 'Put', value: 'options-put' }],
      optionSelected: { name: 'Call', value: 'options-call' },
      status: this.$i18n.locale === 'th' ? 'ณ วันที่ ' : this.$t('dateInfo', { date: '' }),
      accordianItems: [
        {
          name: 'Precious Metal',
          volume: numberWithCommas(65516),
          oi: numberWithCommas(71569),
          action: true
        }, {
          name: 'Agriculture',
          volume: '',
          oi: numberWithCommas(187),
          action: true
        }
      ],
      accordianChild: [
        {
          name: 'Deferred Precious Metal',
          volume: '',
          oi: numberWithCommas(1)
        }, {
          name: 'Currency',
          volume: numberWithCommas(14766),
          oi: numberWithCommas(115010)
        }, {
          name: 'Interest Rate',
          volume: '',
          oi: ''
        }
      ],
      fields_future_overview: [
        {
          label: 'Futures',
          key: 'name',
          thClass: 'tmw-100 ps-4 pe-1',
          tdClass: 'tmw-100 ps-4 pe-1'
        },
        {
          label: this.$t('homePage.marketUpdate.volumeContract'),
          key: 'volume',
          thClass: 'tmw-80 text-end px-1  text-nowrap',
          tdClass: 'tmw-80 text-end px-1'
        },
        {
          label: this.$t('homePage.marketUpdate.oiStatus'),
          key: 'oi',
          thClass: 'tmw-80 text-end px-1  text-nowrap',
          tdClass: 'tmw-80 text-end px-1'
        },
        {
          label: '',
          key: 'action',
          thClass: 'tmw-24 px-0',
          tdClass: 'tmw-24 text-center px-0'
        }
      ],
      fields_option_overview: [
        {
          label: 'Options',
          key: 'name',
          thClass: 'ps-4 tmw-100 title-font-family fw-bold fs-24px',
          tdClass: 'ps-4 tmw-100'
        },
        {
          label: this.$t('homePage.marketUpdate.volumeContract'),
          key: 'volume',
          thClass: 'tmw-80 text-end  text-nowrap',
          tdClass: 'tmw-80 pe-0 text-end'
        },
        {
          label: this.$t('homePage.marketUpdate.oiStatus'),
          key: 'oi',
          thClass: 'tmw-80 text-end pe-2  text-nowrap',
          tdClass: 'tmw-80 text-end pe-2'
        }
      ],
      fields_future_volume: [
        {
          label: this.$t('homePage.marketUpdate.type'),
          key: 'name',
          thClass: 'ps-4',
          tdClass: 'ps-4'
        },
        {
          label: this.$t('homePage.marketUpdate.buy'),
          key: 'buy',
          thClass: 'text-end mnw-80',
          tdClass: 'text-end mnw-80 pe-0'
        },
        {
          label: this.$t('homePage.marketUpdate.sell'),
          key: 'sell',
          thClass: 'text-end mnw-80',
          tdClass: 'text-end mnw-80 pe-0'
        },
        {
          label: this.$t('homePage.marketUpdate.net'),
          key: 'net',
          thClass: 'mnw-80 text-end pe-2',
          tdClass: 'mnw-80 text-end pe-2'
        }
      ],
      item_future_volume: null,
      fields_option_volume: [
        {
          label: this.$t('homePage.marketUpdate.type'),
          key: 'name',
          thClass: 'ps-4',
          tdClass: 'ps-4'
        },
        {
          label: this.$t('homePage.marketUpdate.buy'),
          key: 'buy',
          thClass: 'text-end mnw-80',
          tdClass: 'text-end mnw-80 pe-0'
        },
        {
          label: this.$t('homePage.marketUpdate.sell'),
          key: 'sell',
          thClass: 'text-end mnw-80',
          tdClass: 'text-end mnw-80 pe-0'
        },
        {
          label: this.$t('homePage.marketUpdate.net'),
          key: 'net',
          thClass: 'mnw-80 text-end pe-2',
          tdClass: 'mnw-80 text-end pe-2'
        }
      ],
      item_option_volume: null
    }
  },
  computed: {
    ...mapState({
      futureDateTime: state => state.home.tfex.futureDateTime,
      futureOption: state => state.home.tfex.futureOption,
      futureInvestorVolume: state => state.home.tfex.futureInvestorVolume,
      optionInvestorVolume: state => state.home.tfex.optionInvestorVolume
    }),
    viewMoreButton () {
      return `https://www.tfex.co.th/${this.$i18n.locale}/market-data/historical-data/trading-by-investor-types`
    }
  },
  watch: {
    async futureSelected (newValue) {
      await this.getTFEXFutureInvestorVolume(newValue.value)
      this.item_future_volume = this.futureInvestorVolume
    },
    async optionSelected (newValue) {
      await this.getTFEXOptionInvestorVolume(newValue.value)
      this.item_option_volume = this.optionInvestorVolume
    }
  },
  async mounted () {
    await Promise.allSettled([
      this.getTFEXFutureOption(),
      this.getTFEXFutureInvestorVolume(this.futureSelected.value),
      this.getTFEXOptionInvestorVolume(this.optionSelected.value)
    ])
    this.item_future_volume = this.futureInvestorVolume
    this.item_option_volume = this.optionInvestorVolume
    this.$emit('awaitContentLoaded', false)
  },
  methods: {
    ...mapActions('home/tfex', ['getTFEXFutureOption', 'getTFEXFutureInvestorVolume', 'getTFEXOptionInvestorVolume']),
    formatDateShort,
    handleClickTracking (name, optionLang) {
      this.$personalized.clickElement({
        name,
        optionLang
      })
    }
  }
}
