//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'HomeInFocusMore',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data () {
    const slideClassName = this.isMobile ? 'infocus-slide-mobile' : 'infocus-slide-pc'
    return {
      slideClassName,
      swiperOption: {
        showPagination: false,
        showNavigation: false,
        watchOverflow: true,
        slidesPerView: 3,
        spaceBetween: 16,
        slidesOffsetAfter: 25,
        slidesOffsetBefore: 25,
        breakpoints: {
          320: {
            slidesPerView: 'auto'
          },
          576: {
            slidesPerView: 'auto'
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 16,
            slidesOffsetAfter: 0,
            slidesOffsetBefore: 0
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 16,
            slidesOffsetAfter: 0,
            slidesOffsetBefore: 0
          }
        },
        pagination: {
          el: `.${slideClassName} .swiper-pagination`,
          clickable: true
        },
        navigation: {
          nextEl: `.${slideClassName} .btn-next`,
          prevEl: `.${slideClassName} .btn-prev`
        }
      }
    }
  },
  methods: {
    swiperInit (swiper) {
      // swiper.on('setTransition', this.handleSlideChange)
      swiper.on('resize', () => {
        swiper.updateSlides()
      })
      // setTimeout(() => {
      //   swiper.update()
      // }, 200)
    },
    handlePrev () {
      if (this.$refs.carousel) {
        this.$refs.carousel.swiper.slidePrev()
      }
    },
    handleNext () {
      if (this.$refs.carousel) {
        this.$refs.carousel.swiper.slideNext()
      }
    },
    handleSlideChange (a) {
      this.updateSwiper()
    },
    linkTarget (openNewTab) {
      if (openNewTab === true) {
        return '_blank'
      }
      return null
    },
    eventTrackingClicked (infocusData) {
      if (infocusData.url && infocusData.url !== '#') {
        this.$personalized.clickElement({
          name: 'home13',
          optionLang: {
            menu: infocusData.name || ''
          }
        })
      }
    }
  }
}
