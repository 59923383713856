//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { integerStyle } from '~/helpers/index'
import { formatDateShort } from '~/helpers/dateTime'
export default {
  name: 'HomeMarketUpdateSetTRI',
  data () {
    return {
      setData: [{
        symbol: '',
        last: '',
        change: '',
        volume: '',
        value: ''
      }],
      fieldsIndex: [
        {
          label: this.$t('stock.fields.index'),
          key: 'symbol',
          thClass: 'text-start text-nowrap text-nowrap mw-82 px-1',
          tdClass: 'title-symbol heading1-font-family fs-20px px-1 mw-82'
        },
        {
          label: this.$t('stock.fields.lastest'),
          key: 'tri',
          thClass: 'text-end text-nowrap mw-100 px-1',
          tdClass: 'text-end text-nowrap fs-20px px-1 mw-100 text-deep-gray'
        },
        {
          label: this.$t('stock.fields.change'),
          key: 'change',
          thClass: 'text-nowrap px-1',
          tdClass: 'text-nowrap px-1',
          sortable: false
        }
      ]
    }
  },
  async fetch () {
    await this.getNetTRIindices().then((res) => {
      if (res?.length) {
        this.setData = res
      }
    })
  },
  computed: {
    setDataInfo () {
      return this.setData
    }
  },
  // async mounted () {
  //   const res = await this.getNetTRIindices()
  //   if (res && res.length) {
  //     this.setData = res
  //   }
  // },
  methods: {
    integerStyle,
    formatDateShort,
    routePathOverview (symbol) {
      if (symbol) {
        const slug = symbol.toLowerCase()
        const url = this.localePath({
          name: 'market-index-slug-overview',
          params: { slug }
        })
        return url || ''
      }
    },
    handleClickTracking (index) {
      if (index === 'mai') {
        this.$personalized.clickElement({
          name: 'home7',
          optionLang: {
            index
          }
        })
      } else {
        this.$personalized.clickElement({
          name: 'home2',
          optionLang: {
            index
          }
        })
      }
    },
    async getNetTRIindices () {
      try {
        const response = await this.$axios.$get('/api/set/tri/indices', {
          params: { lang: this.$i18n.locale }
        })
        const { data } = response ?? {}
        return data?.filter(f => f.symbol.toUpperCase() === 'SET')
      } catch (err) {
        return []
      }
    }
  }
}
