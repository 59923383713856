//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ButtonSymbolLink',
  props: {
    href: {
      type: String,
      default: '#'
    }
  }
}
