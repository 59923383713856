import { render, staticRenderFns } from "./IndexSet.vue?vue&type=template&id=7b4fdc2b&scoped=true&"
import script from "./IndexSet.vue?vue&type=script&lang=js&"
export * from "./IndexSet.vue?vue&type=script&lang=js&"
import style0 from "./IndexSet.vue?vue&type=style&index=0&id=7b4fdc2b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b4fdc2b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconTriangle: require('/app/components/Icon/Triangle.vue').default,Tab: require('/app/components/NavTabs/Tab.vue').default,Tabs: require('/app/components/NavTabs/Tabs.vue').default,HomeMarketUpdate: require('/app/components/Pages/Home/MarketUpdate/MarketUpdate.vue').default,TabPane: require('/app/components/NavTabs/TabPane.vue').default,PlaceholderMarketUpdate: require('/app/components/Placeholder/MarketUpdate.vue').default,ContentLazy: require('/app/components/ContentLazy.vue').default,HomeTFEX: require('/app/components/Pages/Home/TFEX/TFEX.vue').default,TabContent: require('/app/components/NavTabs/TabContent.vue').default})
