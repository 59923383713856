import { render, staticRenderFns } from "./index.vue?vue&type=template&id=56e54c5c&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Tab: require('/app/components/NavTabs/Tab.vue').default,Tabs: require('/app/components/NavTabs/Tabs.vue').default,PlaceholderOppdayCard: require('/app/components/Placeholder/OppdayCard.vue').default,Card: require('/app/components/Card/Card.vue').default,ContentLazy: require('/app/components/ContentLazy.vue').default,TabPane: require('/app/components/NavTabs/TabPane.vue').default,TabContent: require('/app/components/NavTabs/TabContent.vue').default})
