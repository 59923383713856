//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'IconBellNoti'
}
