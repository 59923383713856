//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { openTab } from '~/helpers'
export default {
  name: 'MarketUpdateStock',
  props: {
    handleSelect: {
      type: Function,
      default: () => {}
    },
    listTopRank: {
      type: Array,
      default: Array
    },
    marketTab: {
      type: String,
      default: 'SET'
    }
  },
  data () {
    return {
      active: 0,
      checkRankingType: 'mostActiveValue',
      activeTitle: ''
    }
  },
  // computed: {
  //   setNameComponent () {
  //     return () => import('~/components/Icon/LinkStock.vue')
  //   }
  // },
  created () {
    if (this.listTopRank && this.listTopRank.length) {
      if (this.listTopRank[0].title) {
        this.activeTitle = this.listTopRank[0].title
      }
    }
  },
  // mounted () {
  //   if (this.listTopRank && this.listTopRank.length) {
  //     if (this.listTopRank[0].title) {
  //       this.activeTitle = this.listTopRank[0].title
  //     }
  //   }
  // },
  methods: {
    isActive (active) {
      return this.active === active ? 'active' : ''
    },
    setActive (active, rankingType, title) {
      this.$nextTick(() => {
        this.handleSelect(rankingType, title)
      })
      this.activeTitle = title
      this.checkRankingType = rankingType
      this.active = active
    },
    handleClickViewMore () {
      // this.goStockOverview()
      this.handleClickTracking()
    },
    goStockOverview () {
      const checkRankingType = this.checkRankingType === 'popularSearch' ? 'popular' : this.checkRankingType
      const market = this.marketTab.toLowerCase()
      openTab('market/product/stock/top-ranking?market=' + market + '#rankingType-' + checkRankingType)
    },
    handleClickTracking () {
      if (this.marketTab === 'SET') {
        this.$personalized.clickElement({
          name: 'home5',
          optionLang: {
            type: this.activeTitle
          }
        })
      } else if (this.marketTab === 'mai') {
        this.$personalized.clickElement({
          name: 'home10',
          optionLang: {
            type: this.activeTitle
          }
        })
      }
    }
  }
}
