//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { integerStyle } from '@/helpers/index'
export default {
  name: 'HomeSetNewsItem',
  props: {
    dataObject: {
      type: Object,
      default: () => {}
    },
    eventTrackingName: {
      type: String,
      default: null
    }
  },

  computed: {
    pathNews () {
      const { id, symbol } = this.dataObject ?? {}
      return this.localePath({
        name: 'market-news-and-alert-newsdetails',
        query: {
          id,
          symbol
        }
      })
    }
  },

  methods: {
    integerStyle,
    handleClickTracking () {
      const { symbol, headline } = this.dataObject || {}
      const title = `${symbol}: ${headline}`
      this.$personalized.clickElement({
        name: this.eventTrackingName,
        optionLang: {
          title
        }
      })
    }
  }
}
