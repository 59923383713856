//
//
//
//
//
//
//
//
//
//
//

import { dayjs as _dayjs } from '@/helpers/dateTime'
import { convertToMillionFormat } from '~/helpers/number'
export default {
  name: 'IndexRetro',
  props: {
    dataArray: {
      type: Array,
      default: Array
    }
  },
  data () {
    return {
      chart: null
    }
  },
  watch: {
    dataObj () {
      if (this.chart) {
        this.chart.dispose()
        this.init()
      }
    }
  },
  mounted () {
    setTimeout(() => {
      this.init()
    }, 500)
  },
  beforeDestroy () {
    if (this.chart) {
      this.chart.dispose()
    }
  },
  methods: {
    init () {
      const {
        am5,
        am5xy,
        // am5ThemesAnimated,
        amLangTH
      } = require('~/plugins/amChart5')

      if (!this.$refs.chartdiv) {
        return
      }

      const root = am5.Root.new(this.$refs.chartdiv)

      root.numberFormatter.setAll({
        numberFormat: '#,###.##',
        numericFields: ['valueY']
      })

      if (this.$i18n.locale === 'th') {
        root.locale = amLangTH
      }

      const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          paddingTop: 10,
          paddingRight: 10,
          paddingBottom: 0,
          paddingLeft: 0,
          layout: root.verticalLayout
        })
      )

      chart.leftAxesContainer.set('layout', root.verticalLayout)
      chart.zoomOutButton.set('forceHidden', true)

      // Add data
      const data = this.dataArray.map((item) => {
        return {
          date1: item.date,
          date: _dayjs(item.date).valueOf(), // new Date(item.date),
          institution: convertToMillionFormat(item.institution, false),
          proprietary: convertToMillionFormat(item.proprietary, false),
          foreign: convertToMillionFormat(item.foreign, false),
          individual: convertToMillionFormat(item.individual, false)
        }
      })
      // console.log('🚀 ~ data:', data)

      // <------------------ xAxis -----------> //

      const xRenderer = am5xy.AxisRendererX.new(root, {
        strokeOpacity: 1,
        minGridDistance: 20
      })

      xRenderer.grid.template.setAll({
        visible: false
      })

      xRenderer.ticks.template.setAll({
        length: 5,
        // location: 0.5,
        visible: true
      })

      xRenderer.labels.template.setAll({
        fontSize: 12,
        // minPosition: 0.01,
        // maxPosition: 0.99,
        paddingTop: 10
      })

      const xAxis = chart.xAxes.push(
        am5xy.GaplessDateAxis.new(root, {
          tooltipDateFormat: 'HH:mm',
          baseInterval: {
            timeUnit: 'day',
            count: 1
          },

          extraMax: -0.1,

          gridIntervals: [{ timeUnit: 'day', count: 1 }],
          renderer: xRenderer
        })
      )

      xAxis.get('dateFormats').day = 'dd/MM'
      xAxis.get('periodChangeDateFormats').day = 'dd/MM'

      // <------------------ yAxis -----------> //

      const yRenderer = am5xy.AxisRendererY.new(root, {
        inside: false,
        strokeOpacity: 1,
        strokeWidth: 1,
        stroke: am5.color('#000000')
      })

      yRenderer.grid.template.setAll({
        location: 0,
        multiLocation: 0,
        stroke: am5.color('#7B7D80'),
        strokeOpacity: 0.2,
        strokeWidth: 1
      })

      yRenderer.labels.template.setAll({
        fontSize: 12,
        location: 0
      })

      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          // maxDeviation: 0.2,
          // extraMin: 0.05,
          // extraMax: 0.1,
          // zIndex: 1,
          // strictMinMax: true,
          renderer: yRenderer
        })
      )

      // Create series
      createSeries(
        'institution',
        '#0DB23B',
        this.$t('charts.retro.institution')
      )
      createSeries(
        'proprietary',
        '#5358C0',
        this.$t('charts.retro.proprietary')
      )
      createSeries('foreign', '#F7921E', this.$t('charts.retro.foreign'))
      createSeries('individual', '#2F74FA', this.$t('charts.retro.individual'))

      function createSeries (filed, color, name) {
        const series = chart.series.push(
          am5xy.LineSeries.new(root, {
            name,
            xAxis,
            yAxis,
            categoryXField: filed,
            valueXField: 'date',
            valueYField: filed,
            stroke: am5.color(color),
            fill: am5.color(color)
          })
        )

        series.data.setAll(data)
      }

      const legend = chart.children.push(
        am5.Legend.new(root, {
          marginTop: 10,
          width: am5.percent(100),
          x: am5.percent(60),
          centerX: am5.percent(50),
          useDefaultMarker: true,
          layout: root.gridLayout
        })
      )

      legend.markers.template.setAll({
        width: 10,
        height: 6,
        centerY: am5.percent(40)
      })

      legend.markerRectangles.template.setAll({
        cornerRadiusTL: 1,
        cornerRadiusTR: 1,
        cornerRadiusBL: 1,
        cornerRadiusBR: 1
      })

      legend.labels.template.setAll({
        maxWidth: 60,
        fontFamily: 'Sarabun, sans-serif',
        fontSize: 12
      })

      legend.data.setAll(chart.series.values)

      this.chart = root
    }
  }
}
