//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { convertToPriceFormat, formatNumber } from '~/helpers/number'
import { integerStyle } from '@/helpers/index'
import { imageLoadError } from '@/helpers/image'
export default {
  name: 'HomeIPORecent',
  data () {
    return {
      activeIndex: 0,
      carouselOptions: {
        showPagination: false,
        slidesPerView: 'auto',
        centeredSlides: false,
        grabCursor: true,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        noSwipingClass: 'btn-quote',
        breakpoints: {
          320: {
            slidesPerGroup: 1,
            centeredSlides: true
          },
          768: {
            slidesPerGroup: 2,
            centeredSlides: false,
            slidesOffsetBefore: 16,
            slidesOffsetAfter: 16
          },
          1090: {
            slidesPerGroup: 3,
            slidesOffsetBefore: 48,
            slidesOffsetAfter: 48
          },
          1366: {
            slidesPerGroup: 3,
            slidesOffsetBefore: 196,
            slidesOffsetAfter: 196
          },
          1600: {
            slidesPerGroup: 3,
            slidesOffsetBefore: 312,
            slidesOffsetAfter: 312
          },
          1920: {
            slidesPerGroup: 3,
            slidesOffsetBefore: 462,
            slidesOffsetAfter: 462
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      ipoRecent: 'home/ipo/getHomeIpoRecent'
    }),
    swiper () {
      return this.$refs.ipoRecent.swiper
    },
    textVolumeAndValue () {
      if (this.rankingType === 'mostActiveValue') {
        return this.$t('homePage.ipo.valueBaht')
      } else {
        return this.$t('homePage.ipo.volumeStock')
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.onLoadBgImg()
      this.checkItemQuantity()
      setTimeout(() => {
        this.onSwiperInit()
      }, 100)
    })
  },
  update () {
    this.$nextTick(() => {
      setTimeout(() => {
        this.onSwiperInit()
      }, 100)
    })
  },
  methods: {
    formatNumber,
    integerStyle,
    convertToPriceFormat,
    imageLoadError,
    numberFormat (number) {
      return convertToPriceFormat(number)
    },
    integerCustomStyle (number) {
      if (number && Number(number) < 0) {
        return 'theme-danger'
      } else if (number && Number(number) > 0) {
        return 'theme-success'
      }
    },
    checkItemQuantity () {
      const screenSize = window.innerWidth
      const btnPrev = document.querySelector('.ipo-recent .swiper-btn-prev')
      const btnNext = document.querySelector('.ipo-recent .swiper-btn-next')
      if ((screenSize >= 768 && screenSize < 992) && (this.ipoRecent && this.ipoRecent.length <= 2)) {
        btnPrev.setAttribute('style', 'display:none !important;')
        btnNext.setAttribute('style', 'display:none !important;')
      } else if (screenSize >= 992 && (this.ipoRecent && this.ipoRecent.length <= 3)) {
        btnPrev.setAttribute('style', 'display:none !important;')
        btnNext.setAttribute('style', 'display:none !important;')
      } else if (screenSize < 768 && this.ipoRecent && this.ipoRecent.length < 2) {
        btnPrev.setAttribute('style', 'display:flex !important;')
        btnNext.setAttribute('style', 'display:flex !important;')
      }
    },
    checkScreenSize () {
      const screenSize = window.innerWidth
      const btnPrev = document.querySelector('.ipo-recent .swiper-btn-prev')
      const btnNext = document.querySelector('.ipo-recent .swiper-btn-next')
      if (screenSize > 320 && screenSize < 992) {
        btnPrev.setAttribute('style', 'display:flex;')
        btnNext.setAttribute('style', 'display:flex;')
      } else {
        btnPrev.setAttribute('style', 'display:none !important;')
        btnNext.setAttribute('style', 'display:none !important;')
      }
    },
    onSwiperInit () {
      this.setItemOpacity()
      const element = document.querySelectorAll('.recent-cell')
      this.swiper.on('breakpoint', (e) => {
        this.checkItemQuantity()
        this.setItemOpacity()
        this.swiper.update()
        // this.checkScreenSize()
      })
      this.swiper.on('touchStart', (swiper, event) => {
        if (swiper.target.nodeName !== 'path' && swiper.target.nodeName !== 'svg') {
          for (let i = 0; i < element.length; i++) {
            element[i].setAttribute('style', 'opacity:0.56 !important;')
          }
        }
      })
      this.swiper.on('touchEnd', (swiper, event) => {
        this.setItemOpacity()
      })
      this.swiper.on('change', (swiper) => {
        this.activeIndex = this.swiper.activeIndex
      })
      this.swiper.on('reachEnd', (swiper) => {
        const screenSize = window.innerWidth
        if ((screenSize < 1090 && screenSize >= 768) && (this.ipoRecent && this.ipoRecent.length % 2 !== 0)) {
          this.activeIndex = this.homeIpoUpcoming.length - 1
          this.setItemOpacity()
          setTimeout(() => {
            this.activeIndex = this.swiper.activeIndex
          }, 300)
        }
      })
    },
    setItemOpacity () {
      const screenSize = window.innerWidth
      setTimeout(() => {
        // const prev = document.querySelector('.swiper-slide.recent-cell.swiper-slide-prev')
        // const active = document.querySelector('.swiper-slide.recent-cell.swiper-slide-active')
        // const next = document.querySelector('.swiper-slide.recent-cell.swiper-slide-next')
        const cell = document.querySelectorAll('.swiper-slide.recent-cell')
        let activeIdx = 0
        for (let idx = 0; idx < cell.length; idx++) {
          if (cell[idx].classList.contains('swiper-slide-active')) {
            activeIdx = idx
            break
          }
        }
        if (screenSize >= 1090) {
          cell.forEach((item, index) => {
            if (activeIdx === 0) {
              cell[activeIdx].setAttribute('style', 'opacity:1 !important;')
              cell[activeIdx + 1]?.setAttribute('style', 'opacity:1 !important;')
              cell[activeIdx + 2]?.setAttribute('style', 'opacity:1 !important;')
            } else {
              cell[cell.length - 1].setAttribute('style', 'opacity:1 !important;')
              cell[cell.length - 2]?.setAttribute('style', 'opacity:1 !important;')
              cell[cell.length - 3]?.setAttribute('style', 'opacity:1 !important;')
            }
          })
        } else if (screenSize >= 768) {
          if ((this.activeIndex === cell.length - 1) && cell.length % 2 !== 0) {
            cell[this.activeIndex].setAttribute('style', 'opacity:1 !important;')
          } else if (cell.length % 2 === 0) {
            cell[activeIdx === cell.length - 3 ? activeIdx + 1 : activeIdx].setAttribute('style', 'opacity:1 !important;')
            cell[activeIdx === cell.length - 3 ? activeIdx + 2 : activeIdx + 1].setAttribute('style', 'opacity:1 !important;')
          } else {
            cell[activeIdx].setAttribute('style', 'opacity:1 !important;')
            cell[activeIdx + 1].setAttribute('style', 'opacity:1 !important;')
          }
        } else {
          cell[activeIdx].setAttribute('style', 'opacity:1 !important;')
        }
      }, 100)
    },
    onLoadBgImg () {
      const element = document.querySelectorAll('[id^=ipo-recent-logo-img]')
      let idx = 0
      element.forEach((item, index) => {
        const imgSrc = this.ipoRecent[idx].logoUrl
        const image = new Image()
        image.src = imgSrc
        image.onerror = () => {
          item.style.backgroundImage = "url('')"
        }
        if (idx === this.ipoRecent.length - 1) {
          idx = 0
        } else {
          idx += 1
        }
      })
    },
    symbolPath (securityType, symbol) {
      let url = ''
      if (securityType === 'S') {
        url = `/${this.$i18n.locale}/market/product/stock/quote/${symbol}/price`
      } else if (securityType === 'L') {
        url = `/${this.$i18n.locale}/market/product/etf/quote/${symbol}/price`
      } else if (securityType === 'V') {
        url = `/${this.$i18n.locale}/market/product/dw/quote/${symbol}/price`
      } else if (securityType === 'X') {
        url = `/${this.$i18n.locale}/market/product/dr/quote/${symbol}/price`
      } else if (securityType === 'XF') {
        url = `/${this.$i18n.locale}/market/product/drx/quote/${symbol}/price`
      } else if (symbol && symbol.includes('-R')) {
        const regSymbol = symbol.replace(/-[r,R]/g, '').toUpperCase()
        url = `/${this.$i18n.locale}/market/product/stock/quote/${regSymbol}/major-shareholders?type=nvdr`
        url = `/${this.$i18n.locale}/market/product/stock/quote/${regSymbol}/major-shareholders?type=nvdr`
        // url = `/${this.$i18n.locale}/market/product/stock/quote/${regSymbol}/company-profile/major-shareholders?type=nvdr`
        // url = `/${this.$i18n.locale}/market/product/stock/quote/${regSymbol}/company-profile/major-shareholders?type=nvdr`
      }
      return url
    },
    eventTrackingClicked (recentData) {
      this.$personalized.clickElement({
        name: 'home31',
        optionLang: {
          symbol: recentData.symbol || ''
        }
      })
    }
  }
}
