//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { integerStyle } from '~/helpers/index'
export default {
  name: 'MarketUpdateChart',
  props: {
    ftseList: {
      type: Array,
      default: Array
    }
  },
  methods: {
    integerStyle,
    routePathOverview () {
      // this.$router.push(
      //   this.localePath({
      //     name: 'market-index-slug-overview',
      //     params: { slug: 'ftse-set' }
      //   })
      // )
      const url = this.localePath({
        name: 'market-index-slug-overview',
        params: { slug: 'ftse-set' }
      })
      return url || ''
    },
    handleClickTracking (index) {
      this.$personalized.clickElement({
        name: 'home2',
        optionLang: {
          index
        }
      })
    }
  }
}
