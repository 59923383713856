var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row g-0 pt-b bg-white home-market-update-table",class:{ 'market-items-over-10' : _vm.isOver10Items }},[_c('div',{staticClass:"col-md-7 px-md-3 column-market-index-table"},[_c('TableCustomField',{staticClass:"table-market-overview py-2",attrs:{"items":_vm.setData,"fields":_vm.fieldsIndex,"responsive":false,"table-hover":false,"selectable":false,"show-empty":false},scopedSlots:_vm._u([{key:"head(volume)",fn:function(ref){return [_vm._v("\n        "+_vm._s(_vm.$t('homePage.marketUpdate.volumeThousand'))),_c('sup',[_vm._v("1")])]}},{key:"head(value)",fn:function(ref){return [_vm._v("\n        "+_vm._s(_vm.$t('homePage.marketUpdate.valueMbath'))),_c('sup',[_vm._v("1")])]}},{key:"cell(symbol)",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('a',{staticClass:"text-decoration-none d-flex",attrs:{"target":"_blank","rel":"noopener noreferrer","href":_vm.routePathOverview(item.symbol)},on:{"click":function($event){return _vm.handleClickTracking(item.symbol)}}},[_c('div',{staticClass:"d-flex link"},[_vm._v("\n              "+_vm._s(_vm.$i18n.locale === 'th' ? item.nameTH || '' : item.nameEN || '')+"\n            ")])])])]}},{key:"cell(last)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-between"},[(item.percentChange)?_c('IconTriangle',{class:_vm.integerStyle(item && item.percentChange),attrs:{"width":"12","height":"12"}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"ms-auto"},[_vm._v("\n            "+_vm._s(_vm._f("formatNumber")(item.last))+"\n          ")])],1)]}},{key:"cell(change)",fn:function(ref){
var item = ref.item;
return [_vm._v("\n        "+_vm._s(_vm._f("formatNumberInteger")(item.change))+"\n      ")]}},{key:"cell(volume)",fn:function(ref){
var item = ref.item;
return [(item.volume)?[_vm._v("\n          "+_vm._s(_vm.formatVolume(item.volume / 1000))+"\n        ")]:[_vm._v("\n          -\n        ")]]}},{key:"cell(value)",fn:function(ref){
var item = ref.item;
return [_vm._v("\n        "+_vm._s(_vm._f("convertToMillionFormat")(item.value))+"\n      ")]}}])})],1),_vm._v(" "),_c('div',{staticClass:"col-md-5 d-flex flex-column chart-column-section"},[_c('div',{staticClass:"header-chart px-3 py-1 d-flex"},[_c('div',{staticClass:"symbol heading-font-family fs-20px"},[_vm._v("\n        SET\n      ")]),_vm._v(" "),(_vm.dataObj)?_c('div',{staticClass:"ms-auto d-flex"},[_c('div',{staticClass:"index-lable-item"},[_c('span',{staticClass:"title text-middle-gray pe-1"},[_vm._v(_vm._s(_vm.$t('homePage.marketUpdate.high')))]),_c('span',{staticClass:"detail heading-font-family fs-18px text-deep-gray"},[_vm._v("\n            "+_vm._s(_vm.numberFormat(_vm.dataObj.high) || '-'))])]),_vm._v(" "),_c('div',{staticClass:"index-lable-item"},[_c('span',{staticClass:"title text-middle-gray pe-1"},[_vm._v(_vm._s(_vm.$t('homePage.marketUpdate.low')))]),_c('span',{staticClass:"detail heading-font-family fs-18px text-deep-gray"},[_vm._v("\n            "+_vm._s(_vm.numberFormat(_vm.dataObj.low) || '-'))])])]):_vm._e()]),_vm._v(" "),_c('ContentLazy',{staticClass:"h-100 chart-line-market-wrapper",scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('Placeholder',{staticClass:"h-100 w-100"})]},proxy:true}])},[_vm._v(" "),_c('Charts2LineMarket',{attrs:{"data-obj":_vm.dataChart}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }