import { render, staticRenderFns } from "./IPOCard.vue?vue&type=template&id=66d077e6&"
import script from "./IPOCard.vue?vue&type=script&lang=js&"
export * from "./IPOCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Placeholder: require('/app/components/Placeholder/Placeholder.vue').default,IconTriangle: require('/app/components/Icon/Triangle.vue').default,Divider: require('/app/components/Divider.vue').default})
