//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Home',
  data () {
    return {
      isTH: this.$i18n.locale === 'th',
      isEN: this.$i18n.locale === 'en',
      shortcutStakeholderUuid: null,
      shortcutStakeholderItems: []
    }
  },
  computed: {
    // highlight banner
    highlightBanner () {
      const data = this.$content.getHomeBannerLocation(
        'B_HOME_HIGHLIGHT_BANNER'
      )
      return data
    },
    highlightBannerPersonalized () {
      return this.highlightBanner.isPersonalized
    },
    highlightBannerDesktopClass () {
      return this.highlightBanner.desktopPersonalized
    },
    highlightBannerMobileClass () {
      return this.highlightBanner.mobilePersonalized
    },

    // hero banner
    heroBanner () {
      const data = this.$content.getHomeBannerLocation('B_HOME_HERO')
      return data
    },

    // Infocus
    inFocusList () {
      const menu = this.$content.getHomeMenuLocation('M_IN_FOCUS') || {}
      const menuItems = menu.menuItems || []
      return menuItems
    },
    inFocusData () {
      const data = {
        inFocusItems: this.inFocusList,
        shortcutPartnerItems: this.shortcutPartnerItems
      }
      return data
    },

    // Shortcut stakeholder
    // shortcutStakeholderItems () {
    //   console.log('shortcutStakeholderItems')
    //   const menu = this.$content.getHomeMenuLocation('M_SHORTCUT_STAKEHOLDER') || {}
    //   const menuItems = menu.menuItems || []
    //   return menuItems
    // },
    shortcutPartnerItems () {
      const menu = this.$content.getHomeMenuLocation('M_SHORTCUT_PARTNER') || {}
      const menuItems = menu.menuItems || []
      return menuItems
    }
  },
  mounted () {
    this.getShortcutStakeholderItems()
  },
  methods: {
    async getShortcutStakeholderItems () {
      const menu = await this.$content.getHomeMenuLocation('M_SHORTCUT_STAKEHOLDER') || {}
      const menuItems = menu.menuItems || []
      this.shortcutStakeholderItems = menuItems
    }
  }
}
