import { render, staticRenderFns } from "./MarketUpdate.vue?vue&type=template&id=56ba1f6b&"
import script from "./MarketUpdate.vue?vue&type=script&lang=js&"
export * from "./MarketUpdate.vue?vue&type=script&lang=js&"
import style0 from "./MarketUpdate.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeMarketUpdateListIndex: require('/app/components/Pages/Home/MarketUpdate/ListIndex.vue').default,HomeMarketUpdateStatus: require('/app/components/Pages/Home/MarketUpdate/Status.vue').default,HomeMarketUpdateChartSET: require('/app/components/Pages/Home/MarketUpdate/ChartSET.vue').default,HomeMarketUpdateChartMai: require('/app/components/Pages/Home/MarketUpdate/ChartMai.vue').default,HomeMarketUpdateSetTRI: require('/app/components/Pages/Home/MarketUpdate/SetTRI.vue').default,HomeMarketUpdateFTSESET: require('/app/components/Pages/Home/MarketUpdate/FTSESET.vue').default,HomeMarketUpdateDaySummaryTab: require('/app/components/Pages/Home/MarketUpdate/DaySummaryTab.vue').default,HomeMarketUpdateStock: require('/app/components/Pages/Home/MarketUpdate/MarketUpdateStock.vue').default,Placeholder: require('/app/components/Placeholder/Placeholder.vue').default,CardStockUpdate: require('/app/components/Card/CardStockUpdate.vue').default,Message: require('/app/components/Message/index.vue').default,ButtonViewMore: require('/app/components/Button/ViewMore.vue').default})
