//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { removeCommas } from '@/helpers/number'
export default {
  name: 'CardTFEX',
  props: {
    dataObj: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    removeCommas,
    addEventTracking ({ name, symbol }) {
      this.$personalized.clickElement({
        name: 'homeMarketOverviewTFEX',
        optionLang: {
          type: name,
          quote: symbol
        }
      })
    }
  }
}
