//
//
//
//
//
//

export default {
  name: 'ButtonTabPill',
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
