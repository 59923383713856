import { render, staticRenderFns } from "./Archive.vue?vue&type=template&id=e6a8c4d0&"
import script from "./Archive.vue?vue&type=script&lang=js&"
export * from "./Archive.vue?vue&type=script&lang=js&"
import style0 from "./Archive.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Picture: require('/app/components/Picture/Picture.vue').default,IconPlay: require('/app/components/Icon/Play.vue').default,Button: require('/app/components/Button/Button.vue').default,Card: require('/app/components/Card/Card.vue').default,SwiperCarousel: require('/app/components/SwiperCarousel/SwiperCarousel.vue').default,Message: require('/app/components/Message/index.vue').default})
