var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-tfex"},[_c('div',{staticClass:"card-tfex-header"},[_c('div',{staticClass:"name"},[_vm._v("\n      "+_vm._s(_vm.dataObj.name)+"\n    ")])]),_vm._v(" "),_c('div',{staticClass:"p-2"},[_c('div',{staticClass:"d-flex"},[_c('ButtonSymbolLink',{staticClass:"title",attrs:{"href":_vm.dataObj.url,"target":"_blank"},on:{"click":function($event){return _vm.addEventTracking(_vm.dataObj)}}},[_vm._v("\n        "+_vm._s(_vm.dataObj.symbol)+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"ms-auto"},[_c('client-only',[(_vm.dataObj.chartData)?_c('Charts2MicroLine',{staticClass:"chart-micro",attrs:{"data-obj":_vm.dataObj.chartData}}):_vm._e()],1)],1)],1),_vm._v(" "),_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"point"},[_c('IconTriangle',{class:_vm.dataObj.percent.includes('-')
              ? 'text-danger'
              : _vm.dataObj.percent.includes('+')
                ? 'text-success'
                : 'text-muted',style:(_vm.dataObj.percent.includes('-') ? 'transform: rotate(180deg);' : '')}),_vm._v("\n        "+_vm._s(_vm._f("formatNumber")(_vm.removeCommas(_vm.dataObj.last)))+"\n      ")],1),_vm._v(" "),_c('div',{staticClass:"text ms-auto"},[_c('small',[_vm._v("\n          "+_vm._s(_vm.$t('homePage.marketUpdate.volumeContract'))+"\n        ")])])]),_vm._v(" "),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"percent",class:_vm.dataObj.percent.includes('-')
            ? 'text-danger'
            : _vm.dataObj.percent.includes('+')
              ? 'text-success'
              : 'text-muted'},[_vm._v("\n        "+_vm._s(_vm.dataObj.percent)+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"ms-auto"},[_vm._v("\n        "+_vm._s(_vm.dataObj.volume)+"\n      ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }