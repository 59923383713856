//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { deviceSizes } from '@/config/core'
export default {
  name: 'HomeInFocus',
  props: {
    infocusData: {
      type: Object,
      default: Object
    }
  },
  data () {
    return {
      marketReportName: null,
      marketReportUrl: null,
      backgroundImage: '/images/facts-and-figures-bg.jpg',
      factList: [
        {
          id: '1',
          image: 'https://www.dutchboostinggroup.nl/wp-content/uploads/2020/06/Omgeving1-1.jpg',
          topic: 'แนวโน้มธุรกิจ/อุตสาหกรรม ผลิตไฟฟ้าที่ใช้เชื้อเพลิงจากพลังงานหมุนเวียน',
          category: ''
        }, {
          id: '2',
          image: 'https://jessica198112511.files.wordpress.com/2018/09/2.jpg?w=400&h=267',
          topic: 'การลงทุนในธุรกิจที่ยั่งยืน สร้างผลตอบแทนในระยะยาว',
          category: 'video',
          videoId: '_qrLxOdwUfk'
        }, {
          id: '3',
          image: 'https://img.freepik.com/free-photo/close-up-keyboard-glasses-with-executives-background_1098-3635.jpg?size=626&ext=jpg',
          topic: 'ส่องนักลงทุนต่างประเทศในตลาดหุ้นไทย ในรอบ 10 ปี',
          category: ''
        }
      ],
      setMarketData: {
        market: null,
        date: null,
        marketCap: null,
        companyAmount: null
      },
      maiMarketData: {
        market: null,
        date: null,
        marketCap: null,
        companyAmount: null
      },
      isMobile: this.$device.isMobile
    }
  },
  computed: {
    ...mapGetters({
      setFundamental: 'home/set-mai/getSetFundamental',
      maiFundamental: 'home/set-mai/getMaiFundamental'
    }),
    inFocusItems () {
      return this.infocusData.inFocusItems
    },
    infocusHighlight () {
      return this.inFocusItems[0]
    },
    infocusMoreItem () {
      return this.inFocusItems.slice(1)
    }
  },
  mounted () {
    this.getUrlMarketSum()
    this.setFundamentalData()
    this.registerEvent()
    this.handleResponsive()
  },
  beforeDestroy () {
    this.removeEvent()
  },
  methods: {
    registerEvent () {
      window.addEventListener('resize', this.handleResize)
    },
    removeEvent () {
      window.removeEventListener('resize', this.handleResize)
    },
    previous () {
      this.$refs.carousel.previous()
    },
    next () {
      this.$refs.carousel.next()
    },
    modalVideoOpen (id) {
      this.$refs.modalVideo.modalOpen({
        videoId: id
      })
    },
    handleResize () {
      this.handleResponsive()
    },
    handleResponsive () {
      const windowWidth = window.innerWidth
      this.isMobile = windowWidth < deviceSizes.md
    },
    async getUrlMarketSum () {
      await this.$content.getMenuByLocation('M_MKT_SUM')
        .then((menu) => {
          if (menu && menu.menuItems.length) {
            this.marketSum = menu.menuItems
            this.marketReportName = menu.menuItems[0].name
            this.marketReportUrl = menu.menuItems[0].url
          }
        })
        .catch(err => err)
      this.loading = false
    },

    /**
     * Set fundamental data
     */
    async setFundamentalData () {
      const { formatDateShort } = require('@/helpers/dateTime')

      await this.$store.dispatch('home/set-mai/getSetFundamental')
      await this.$store.dispatch('home/set-mai/getMaiFundamental')

      this.setMarketData = {
        market: this.setFundamental.market,
        date: formatDateShort(this.setFundamental.date, this.$i18n.locale),
        // marketCap: this.setFundamental.marketCap ? new Intl.NumberFormat().format((this.setFundamental.marketCap / 1000000000).toFixed(0)) : null,
        marketCap: this.setFundamental.marketCap ? (this.setFundamental.marketCap / 1000000000).toFixed(0) : null,
        companyAmount: this.setFundamental.numberOfListedCompany ? new Intl.NumberFormat().format(this.setFundamental.numberOfListedCompany) : null
      }

      this.maiMarketData = {
        market: this.maiFundamental.market,
        date: formatDateShort(this.maiFundamental.date, this.$i18n.locale),
        // marketCap: this.maiFundamental.marketCap ? new Intl.NumberFormat().format((this.maiFundamental.marketCap / 1000000000).toFixed(0)) : null,
        marketCap: this.maiFundamental.marketCap ? (this.maiFundamental.marketCap / 1000000000).toFixed(0) : null,
        companyAmount: this.maiFundamental.numberOfListedCompany ? new Intl.NumberFormat().format(this.maiFundamental.numberOfListedCompany) : null
      }
    }
  }
}
