//
//
//
//
//
//

import cloneDeep from 'lodash/cloneDeep'
import { formatNumber } from '@/helpers/number'

export default {
  name: 'Charts2LineMarket',
  props: {
    dataObj: {
      type: Object,
      default: Object
    }
  },

  data () {
    return {
      chart: null
    }
  },

  computed: {
    renderChartData () {
      return cloneDeep(this.dataObj) || []
    }
  },

  watch: {
    renderChartData (renderChartData) {
      this.clearChart()
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.init()
    })
  },

  beforeDestroy () {
    if (this.chart) {
      this.chart.dispose()
    }
  },

  methods: {
    clearChart () {
      if (this.chart) {
        this.chart.dispose()
        this.init()
      }
    },
    init () {
      const prior = this.renderChartData && this.renderChartData.prior
      const data =
        (this.renderChartData &&
          this.renderChartData.quotations &&
          this.renderChartData.quotations
            .sort((a, b) => new Date(a.localDatetime) - new Date(b.localDatetime))
            .map((item, index) => {
              return {
                ...item,
                price: item.price === null && index === 0 ? prior : item.price,
                datetime: new Date(item.localDatetime).getTime(),
                priceLabel: formatNumber(item.price)
              }
            })) ||
        []
      // const chartData = data.sort((a, b) => {
      //   return new Date(a.datetime) - new Date(b.datetime)
      // })
      // const chartDate = chartData[0] ? chartData[0].datetime : ''

      const { am5, am5xy } = require('@/plugins/amChart5')

      const root = am5.Root.new(this.$refs.chartdiv)
      // root.setThemes([am5ThemesAnimated.new(root)])
      root.numberFormatter.setAll({
        numberFormat: '#,###.00',
        numericFields: ['valueY']
      })

      const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          paddingTop: 10,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 5
        })
      )

      chart.leftAxesContainer.set('layout', root.verticalLayout)

      chart.set(
        'cursor',
        am5xy.XYCursor.new(root, {
          behavior: 'none',
          fontSize: 2
        })
      )

      // <--------- xAxis ---------->

      const tooltipDate = am5.Tooltip.new(root, {})

      tooltipDate.label.setAll({
        fontSize: 12,
        paddingTop: -2
      })

      tooltipDate.get('background').setAll({
        fill: am5.color('#000000'),
        fillOpacity: 1
      })

      const xRenderer = am5xy.AxisRendererX.new(root, {
        inside: false,
        strokeOpacity: 1,
        strokeWidth: 1,
        stroke: am5.color('#dedede'),
        minGridDistance: 100
      })

      xRenderer.grid.template.setAll({
        location: 0,
        multiLocation: 0,
        visible: false
      })

      xRenderer.ticks.template.setAll({
        visible: true,
        strokeWidth: 1,
        length: 5,
        location: 0
      })

      xRenderer.labels.template.setAll({
        paddingTop: 10,
        fontSize: 12,
        location: 0
      })

      const xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          categoryField: 'datetime',
          tooltipDateFormat: 'HH:mm',
          // maxDeviation: 0.1,
          // startLocation: 0,
          // endLocation: 0.5,
          // extraMin: -0.1,
          baseInterval: {
            timeUnit: 'minute',
            count: 1
          },
          markUnitChange: false,
          gridIntervals: [{ timeUnit: 'hour', count: 2 }],
          renderer: xRenderer,
          tooltip: tooltipDate
        })
      )

      // <--------- YAxis ---------->

      const yRenderer = am5xy.AxisRendererY.new(root, {
        opposite: true,
        strokeOpacity: 1,
        strokeWidth: 1,
        stroke: am5.color('#dedede')
      })

      yRenderer.grid.template.setAll({
        visible: false
      })

      yRenderer.labels.template.setAll({
        fontSize: 12,
        paddingLeft: 10,
        textAlign: 'center'
      })

      const yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          // maxDeviation: 0.2,
          baseValue: prior,
          renderer: yRenderer
        })
      )

      // const date = new Date(chartDate)
      // dateOption.min = new Date(
      //   date.getFullYear(),
      //   date.getMonth(),
      //   date.getDate(),
      //   10,
      //   30
      // ).getTime()
      // dateOption.max = new Date(
      //   date.getFullYear(),
      //   date.getMonth(),
      //   date.getDate(),
      //   17,
      //   11
      // ).getTime()

      root.numberFormatter.set('numberFormat', '#,###.00')

      yAxis.set(
        'numberFormatter',
        am5.NumberFormatter.new(root, {
          numberFormat: '#,###.00'
        })
      )

      this.addSeries(chart, am5xy, am5, root, xAxis, yAxis, data, prior)
      // chart.appear(1000, 100)
      this.chart = root
    },

    addSeries (chart, am5xy, am5, root, xAxis, yAxis, data, prior) {
      const series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: 'SET',
          xAxis,
          yAxis,
          valueYField: 'price',
          valueXField: 'datetime',
          stroke: am5.color('#16BD45')
        })
      )

      series.strokes.template.setAll({
        strokeWidth: 2
      })

      series.fills.template.setAll({
        visible: true,
        opacity: 1
      })
      this.addTooltip(root, am5, series, prior)

      // this.addLineLastPricePeriod(yAxis, series, am5)

      series.fills.template.set(
        'fillGradient',
        am5.LinearGradient.new(root, {
          stops: [
            {
              color: am5.color('#17AF54'),
              opacity: 0.4
            },
            {
              color: am5.color('#17AF54'),
              opacity: 0.2
            },
            {
              color: am5.color('#17AF54'),
              opacity: 0
            }
          ],
          rotation: 0
        })
      )

      // <------------------ range -----------> //

      const rangePriorItem = yAxis.makeDataItem({
        value: prior
      })

      const rangePrior = yAxis.createAxisRange(rangePriorItem)

      rangePrior.get('grid').setAll({
        stroke: am5.color('#396478'),
        strokeWidth: 1,
        strokeOpacity: 1,
        strokeDasharray: [2, 2],
        visible: true
      })

      // rangePrior.fills.template.setAll({
      //   visible: true,
      //   opacity: 1
      // })

      // rangePriorItem.get('grid').setAll({
      //   stroke: am5.color('#396478'),
      //   strokeWidth: 1,
      //   strokeOpacity: 1,
      //   strokeDasharray: [4, 4],
      //   visible: true
      // })

      // rangePriorItem.get('label').setAll({
      //   inside: true
      // })

      const rangeDataItem = yAxis.makeDataItem({
        value: prior,
        endValue: 0
      })

      const rangeData = series.createAxisRange(rangeDataItem)

      rangeData.strokes.template.setAll({
        stroke: am5.color('#EC3E3E'),
        strokeWidth: 2
      })

      rangeData.fills.template.setAll({
        visible: true,
        fillOpacity: 1
      })

      rangeData.fills.template.set(
        'fillGradient',
        am5.LinearGradient.new(root, {
          stops: [
            {
              color: am5.color('#EC3E3E'),
              opacity: 0.4
            },
            {
              color: am5.color('#EC3E3E'),
              opacity: 0.2
            },
            {
              color: am5.color('#EC3E3E'),
              opacity: 0
            }
          ],
          rotation: 0
        })
      )
      series.data.setAll(data)
      // series.appear(1000)
    },

    // addLineLastPricePeriod (yAxis, series, am5) {
    //   const seriesRangeDataItemY = yAxis.makeDataItem({
    //     value: 1000,
    //     endValue: 0
    //   })
    //   const seriesRange = series.createAxisRange(seriesRangeDataItemY)
    //   seriesRange.fills.template.setAll({
    //     visible: true,
    //     opacity: 1
    //   })

    //   seriesRangeDataItemY.get('grid').setAll({
    //     strokeOpacity: 1,
    //     visible: true,
    //     stroke: am5.color('#545557'),
    //     strokeDasharray: [4, 4]
    //   })
    // },

    addTooltip (root, am5, series, prior) {
      const tooltipHTML =
        '<div class="tooltip-custom"><span class="tooltip-text">{priceLabel}<span></div>'
      const tooltip = am5.Tooltip.new(root, {
        getFillFromSprite: false,
        getStrokeFromSprite: true,
        autoTextColor: false,
        getLabelFillFromSprite: true,
        labelHTML: tooltipHTML,
        pointerOrientation: 'down',
        paddingTop: -0.25,
        paddingBottom: -0.25
      })
      tooltip.get('background').setAll({
        fill: am5.color('#FFFFFF'),
        fillOpacity: 1,
        stroke: am5.color('#DEE4E8'),
        strokeWidth: 2
      })
      series.set('tooltip', tooltip)
      series.get('tooltip').label.adapters.add('text', function (text, target) {
        const value = target.dataItem?.dataContext.price || null
        const tooltipText = document.querySelector('.tooltip-text')
        if (value) {
          if (value >= prior) {
            tooltipText.classList.add('text-green')
            tooltip.get('background').setAll({
              stroke: am5.color('#16BD45')
            })
          } else {
            tooltipText.classList.add('text-red')
            tooltip.get('background').setAll({
              stroke: am5.color('#F52727')
            })
          }
        }
      })
    }
  }
}
