import { render, staticRenderFns } from "./BellNoti.vue?vue&type=template&id=0ca4a776&scoped=true&"
import script from "./BellNoti.vue?vue&type=script&lang=js&"
export * from "./BellNoti.vue?vue&type=script&lang=js&"
import style0 from "./BellNoti.vue?vue&type=style&index=0&id=0ca4a776&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ca4a776",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconBell: require('/app/components/Icon/Bell.vue').default})
