//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// eslint-disable-next-line no-unused-vars
import { formatNumber } from '@/helpers/number'
const { mergeObject, numberWithCommas } = require('@/helpers')
export default {
  name: 'HomeTFEX',
  props: {
    dataObject: {
      type: Object,
      default: () => {}
    },
    awaitContentLoaded: {
      type: Boolean
    }
  },
  data () {
    return {
      marketDateTime: '',
      instruments: [],
      item_future_overview: [],
      item_option_overview: []
    }
  },
  async created () {
    this.setTfexdata()
    // await this.getTFEXMarketSummary()
    await this.getTFEXActiveInstrucment()
  },
  methods: {
    formatNumber,
    setTfexdata () {
      this.marketDateTime = this.dataObject.marketDateTime
      this.item_future_overview = this.dataObject.item_future_overview
      this.item_option_overview = this.dataObject.item_option_overview
    },
    // async getTFEXMarketSummary () {
    //   await this.$axios.get('api/set/tfex/market/summary').then((res) => {
    //     if (res.status === 200) {
    //       const data = res.data
    //       const date = new Date(data.marketTime)
    //       this.marketDateTime = dayjs(date).locale(this.$i18n.locale).format('DD/MM/YYYY HH:mm:ss')
    //       this.item_future_overview = [{ name: 'Total Futures', volume: data.totalFutures.volume, oi: data.totalFutures.oi }, ...data.futures]
    //       this.item_option_overview = [{ name: 'Total Options', volume: data.totalOptions.volume, oi: data.totalOptions.oi },
    //         { name: 'Call', volume: data.options[0].call.volume, oi: data.options[0].call.oi },
    //         { name: 'Put', volume: data.options[0].put.volume, oi: data.options[0].put.oi },
    //         { name: 'Put/Call', volume: data.options[0].pcRatio.volume, oi: data.options[0].pcRatio.oi }]
    //     }
    //   })
    // },
    async getTFEXActiveInstrucment () {
      let resultData = []
      let mostActiveServiceList = []
      await this.$axios
        .get('api/set/tfex/instrument/active-instrument')
        .then(async (res) => {
          if (res.status === 200) {
            resultData = res.data.instruments
            for (const item of resultData) {
              mostActiveServiceList = [
                ...mostActiveServiceList,
                this.$axios.get(
                  `api/set/tfex/instrument/${item.key}/mostactive?limit=1`
                )
              ]
            }
            await Promise.allSettled([...mostActiveServiceList]).then(
              async (res) => {
                for (const [indexData, { value }] of res.entries()) {
                  if (value.status === 200 && value.data.series[0]) {
                    await this.$axios
                      .get(
                        `api/set/tfex/series/${value.data.series[0].symbol}/chart-quotation?period=1D`
                      )
                      .then((chart) => {
                        if (value) {
                          const item = mergeObject(
                            value.data,
                            ...value.data.series
                          )
                          this.instruments.push({
                            name: resultData[indexData].value,
                            symbol: item.symbol,
                            url: `${process.env.WEBSITE_TFEX}/redirect?symbol=${item.symbol}`,
                            change: item.change
                              ? numberWithCommas(item.change)
                              : 0,
                            last: item.last ? numberWithCommas(item.last) : 0,
                            volume: item.volume
                              ? numberWithCommas(item.volume)
                              : 0,
                            percent: `${
                              item.change == null
                                ? 0
                                : item.change > 0
                                ? `+${formatNumber(item.change)}`
                                : `${formatNumber(item.change)}`
                            }
                      (${
                        item.percentChange > 0
                          ? `+${
                              item.percentChange
                                ? item.percentChange.toFixed(2)
                                : (0).toFixed(2)
                            }`
                          : `${
                              item.percentChange
                                ? item.percentChange.toFixed(2)
                                : (0).toFixed(2)
                            }`
                      } %)`,
                            chartData: chart.data
                          })
                        }
                      })
                  }
                }
              }
            )
            // resultData.forEach(async (item, index) => {
            //   await this.$axios
            //     .get(`api/set/tfex/instrument/${item.key}/mostactive?limit=1`)
            //     .then(async (res) => {
            //       if (res.status === 200) {
            //         item = mergeObject(item, ...res.data.series)
            //         await this.$axios.get(
            //           `api/set/tfex/series/${item.symbol}/chart-quotation?period=1D`
            //         ).then((res) => {
            //           this.instruments.push({
            //             name: item.value,
            //             symbol: item.symbol,
            //             change: item.change ? numberWithCommas(item.change) : 0,
            //             last: item.last ? numberWithCommas(item.last) : 0,
            //             volume: item.volume ? numberWithCommas(item.volume) : 0,
            //             percent: `${
            //               item.change == null
            //                 ? 0
            //                 : item.change > 0
            //                 ? `+${formatNumber(item.change)}`
            //                 : `${formatNumber(item.change)}`
            //             }
            //         (${
            //           item.percentChange > 0
            //             ? `+${
            //                 item.percentChange
            //                   ? item.percentChange.toFixed(2)
            //                   : (0).toFixed(2)
            //               }`
            //             : `${
            //                 item.percentChange
            //                   ? item.percentChange.toFixed(2)
            //                   : (0).toFixed(2)
            //               }`
            //         } %)`,
            //             chartData: res ? res.data : null
            //           })
            //           console.log('🚀 ~ this.instruments:', this.instruments)
            //         })
            //       }
            //     })
            // })
          }
        })
    }
  }
}
