//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'HomeIPO',
  data () {
    return {
      currentTab: '',
      backgroundImage: '/images/ipo-bg.jpg'
    }
  },
  computed: {
    ...mapGetters({
      homeIpoFirstday: 'home/ipo/getHomeIpoFirstday',
      homeIpoUpcoming: 'home/ipo/getHomeIpoUpcoming',
      homeIpoRecent: 'home/ipo/getHomeIpoRecent'
    }),
    firstdayExist () {
      return this.homeIpoFirstday && this.homeIpoFirstday.length > 0
    },
    upcomingExist () {
      return this.homeIpoUpcoming && this.homeIpoUpcoming.length > 0
    },
    recentExist () {
      return this.homeIpoRecent && this.homeIpoRecent.length > 0
    },
    ipoFirstdayViewmore () {
      return this.localePath('/listing/ipo/first-trading-day')
    },
    ipoUpcomingViewmore () {
      return this.localePath('/listing/ipo/upcoming-ipo/set')
    },
    ipoRecentViewmore () {
      return this.localePath('/listing/ipo/ipo-statistics')
    }
  },
  async mounted () {
    await this.getHomeIPO().then(() => {
      if (this.firstdayExist) {
        this.currentTab = 'firstday'
      } else if (this.upcomingExist) {
        this.currentTab = 'upcoming'
      } else if (this.recentExist) {
        this.currentTab = 'recent'
      }
    })
  },
  methods: {
    ...mapActions({
      getHomeIPO: 'home/ipo/getHomeIPO'
    }),
    eventTrackingUpcomingClicked () {
      this.$personalized.clickElement({
        name: 'home27'
      })
    },
    eventTrackingFirstdayClicked () {
      this.$personalized.clickElement({
        name: 'home30'
      })
    },
    eventTrackingRecentClicked () {
      this.$personalized.clickElement({
        name: 'home32'
      })
    }
  }
}
