import { render, staticRenderFns } from "./MarketOverview.vue?vue&type=template&id=48cd334b&"
import script from "./MarketOverview.vue?vue&type=script&lang=js&"
export * from "./MarketOverview.vue?vue&type=script&lang=js&"
import style0 from "./MarketOverview.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeTFEXTableTFEX: require('/app/components/Pages/Home/TFEX/TableTFEX.vue').default,ButtonViewMore: require('/app/components/Button/ViewMore.vue').default,InputSelect: require('/app/components/Input/Select.vue').default})
