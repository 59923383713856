
import ICountUp from 'vue-countup-v2'
import { defaultObserverOption } from '~/config/core'
export default {
  name: 'CountUp',
  components: {
    ICountUp
  },
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    number: {
      type: [Number, String],
      default: 0
    },
    decimalPlaces: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      delay: -1,
      endVal: 0,
      options: {
        decimalPlaces: this.decimalPlaces,
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: ''
      }
    }
  },
  computed: {
    element () {
      return this.$el
    }
  },
  methods: {
    onReady (instance, CountUp) {
      const that = this
      this.createObserver(instance, that)
    },
    createObserver (instanceCount, thatCount) {
      const options = {
        ...defaultObserverOption
      }
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const number = parseFloat(this.number)
            instanceCount.update(thatCount.endVal + number)
            observer.unobserve(this.element)
          }
        })
      }, options)
      observer.observe(this.element)
    },
    isFloat (n) {
      return Number(n) === n && n % 1 !== 0
    }
  },
  render (createElement) {
    return createElement(
      this.tag,
      {},
      [
        createElement(ICountUp, {
          on: {
            ready: this.onReady
          },
          props: {
            delay: this.delay,
            endVal: this.endVal,
            options: this.options
          }
        })
      ])
  }
}
