//
//
//
//
//
//
//

import { formatDateTimeLong } from '~/helpers/dateTime'
export default {
  name: 'HomeTFEXStatus',
  props: {
    dateTime: {
      type: Date,
      default: ''
    }
  },
  mounted () {
  },
  methods: {
    formatDateTimeLong
  }
}
