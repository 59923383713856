//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  convertToPriceFormat,
  convertToMillionFormat,
  formatNumberInteger,
  formatNumberPercent
} from '~/helpers/number'
import { integerStyle } from '~/helpers/index'
export default {
  name: 'MarketUpdateChartMai',
  props: {
    dataObj: {
      type: Object,
      default: Object
    },
    dataChart: {
      type: Object,
      default: () => {}
    },
    loadingChart: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  computed: {
    change () {
      if (this.dataObj.change && this.dataObj.percentChange) {
        return `${this.numberFormatInt(
          this.dataObj.change
        )} (${this.numberFormatPercent(this.dataObj.percentChange)})`
      }

      return '-'
    }
  },
  methods: {
    integerStyle,
    routePathOverview (symbol) {
      if (symbol) {
        const slug = symbol.toLowerCase()
        // this.$router.push(
        //   this.localePath({
        //     name: 'market-index-slug-overview',
        //     params: { slug }
        //   })
        // )
        const url = this.localePath({
          name: 'market-index-slug-overview',
          params: { slug }
        })
        return url || ''
      }
      // if (slug === 'set') {
      //   this.$router.push(
      //     this.localePath({
      //       name: 'market-index-slug-overview',
      //       params: { slug }
      //     })
      //   )
      // }
      // if (slug === 'ftse-set') {
      //   this.$router.push(
      //     this.localePath({
      //       name: 'market-index-slug-overview',
      //       params: { slug: 'ftse-set' }
      //     })
      //   )
      // } if (slug === 'ftse-asean') {
      //   this.$router.push(
      //     this.localePath({
      //       name: 'market-index-slug-overview',
      //       params: { slug: 'ftse-asean' }
      //     })
      //   )
      // } if (slug === 'foreign-index' && slug === 'set') {
      //   this.$router.push(
      //     this.localePath({
      //       name: 'market-index-slug-overview',
      //       params: { slug: 'set' }
      //     })
      //   )
      // }
    },
    numberFormat (number) {
      return convertToPriceFormat(number)
    },
    numberFormatMillion (number) {
      return convertToMillionFormat(number)
    },
    numberFormatInt (number) {
      return formatNumberInteger(number)
    },
    numberFormatPercent (number) {
      return formatNumberPercent(number)
    },
    handleClickTracking (index) {
      if (index === 'mai') {
        this.$personalized.clickElement({
          name: 'home7',
          optionLang: {
            index
          }
        })
      } else {
        this.$personalized.clickElement({
          name: 'home2',
          optionLang: {
            index
          }
        })
      }
    }
  }
}
