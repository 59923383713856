const imageLoadError = (event) => {
  // const imgError = require('@/static/images/logo.png')
  const img = event.target
  if (!img) { return }
  img.style.display = 'none'
}
// const imageLoadError = (event) => {
//   const imgError = require('@/static/images/logo.png')
//   const img = event.target
//   if (!img) { return }
//   img.src = imgError
// }
const gcd = (a, b) => {
  if (b === 0) {
    return a
  }
  return gcd(b, a % b)
}
const aspectRatio = (w, h) => {
  const _gcd = gcd(w, h)
  return [w / _gcd, h / _gcd]
}
export { imageLoadError, aspectRatio }
