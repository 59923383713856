var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-tfex table-responsive mb-0"},[(_vm.category.includes('futureOverview'))?_c('table',{staticClass:"table b-table tb-overview table-custom-field mb-4"},[_c('thead',[_c('tr',[_c('th',{staticClass:"tmw-100 ps-4 pe-1 text-deep-gray title-font-family fw-bold fs-24px"},[_vm._v("\n          Futures\n        ")]),_vm._v(" "),_c('th',{staticClass:"tmw-80 px-1 text-end  text-nowrap"},[_vm._v("\n          "+_vm._s(_vm.$t('homePage.marketUpdate.volumeContract'))+"\n        ")]),_vm._v(" "),_c('th',{staticClass:"tmw-80 px-1 text-end  text-nowrap"},[_vm._v("\n          "+_vm._s(_vm.$t('homePage.marketUpdate.oiStatus'))+"\n        ")]),_vm._v(" "),_c('th',{staticClass:"tmw-24 text-end"})])]),_vm._v(" "),_c('tbody',_vm._l((_vm.items),function(parentItem,index){return _c('tr',{key:index,class:parentItem.segments && parentItem.segments.length > 0
            ? 'tr-accordion'
            : ''},[(parentItem.segments && parentItem.segments.length > 0)?_c('td',{staticClass:"td-accordion p-0",attrs:{"colspan":"12"}},[_c('Accordion',{staticClass:"TFEX-accordian",attrs:{"id":'TFEX-accordian' + index}},[_c('AccordionItem',{ref:'TFEXAccordionItem' + index,refInFor:true,attrs:{"parent":"TFEX-accordion","item-id":index + '',"active":false},scopedSlots:_vm._u([{key:"accordionHeader",fn:function(){return [_c('table',{staticClass:"w-100"},[_c('tbody',{staticClass:"border-0"},[_c('tr',{staticStyle:{"background":"#f1f2f3 !important"}},[_c('td',{staticClass:"tmw-100 ps-4 pe-1"},[_vm._v("\n                        "+_vm._s(_vm._f("dashIfEmpty")(parentItem.name))+"\n                      ")]),_vm._v(" "),_c('td',{staticClass:"tmw-80 px-2 text-end"},[_vm._v("\n                        "+_vm._s(_vm.formatNumber(parentItem.volume, 0))+"\n                      ")]),_vm._v(" "),_c('td',{staticClass:"tmw-80 px-2 text-end"},[_vm._v("\n                        "+_vm._s(_vm.formatNumber(parentItem.oi, 0))+"\n                      ")])])])])]},proxy:true},{key:"accordionBody",fn:function(){return [_c('TableCustomField',{staticClass:"tb-overview-accordion table-responsive TFEX-table-no-header",attrs:{"fields":_vm.fields,"items":parentItem.segments,"selectable":false,"table-hover":false},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
            var item = ref.item;
return [_vm._v("\n                    "+_vm._s(item.name)+"\n                  ")]}},{key:"cell(volume)",fn:function(ref){
            var item = ref.item;
return [_vm._v("\n                    "+_vm._s(_vm.formatNumber(item.volume, 0))+"\n                  ")]}},{key:"cell(oi)",fn:function(ref){
            var item = ref.item;
return [_vm._v("\n                    "+_vm._s(_vm.formatNumber(item.oi, 0))+"\n                  ")]}},{key:"cell(action)",fn:function(ref){return undefined}}],null,true)})]},proxy:true}],null,true)})],1)],1):_vm._e(),_vm._v(" "),(parentItem.segments && parentItem.segments.length === 0)?_c('td',{staticClass:"tmw-100 ps-4 pe-1"},[_vm._v("\n          "+_vm._s(parentItem.name)+"\n        ")]):_vm._e(),_vm._v(" "),(parentItem.segments && parentItem.segments.length === 0)?_c('td',{staticClass:"tmw-80 px-1 text-end"},[_vm._v("\n          "+_vm._s(_vm.formatNumber(parentItem.volume, 0))+"\n        ")]):_vm._e(),_vm._v(" "),(parentItem.segments && parentItem.segments.length === 0)?_c('td',{staticClass:"tmw-80 px-1 text-end"},[_vm._v("\n          "+_vm._s(_vm.formatNumber(parentItem.oi, 0))+"\n        ")]):_vm._e(),_vm._v(" "),(parentItem.segments && parentItem.segments.length === 0)?_c('td',{staticClass:"tmw-24"}):_vm._e()])}),0)]):(_vm.category.includes('optionOverview'))?_c('TableCustomField',{staticClass:"tb-overview tb-overview-option",attrs:{"fields":_vm.fields,"items":_vm.items,"selectable":false,"table-hover":false},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
            var item = ref.item;
return [_vm._v("\n      "+_vm._s(item.name)+"\n    ")]}},{key:"cell(volume)",fn:function(ref){
            var item = ref.item;
return [_vm._v("\n      "+_vm._s(item.volume)+"\n    ")]}},{key:"cell(oi)",fn:function(ref){
            var item = ref.item;
return [_vm._v("\n      "+_vm._s(item.oi)+"\n    ")]}}])}):(_vm.category.includes('futureVolume'))?_c('TableCustomField',{attrs:{"fields":_vm.fields,"items":_vm.items,"selectable":false,"table-hover":false},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
            var item = ref.item;
return [_vm._v("\n      "+_vm._s(item.name === 'institution' ? _vm.$t('homePage.marketUpdate.institution') : (item.name === 'foreign' ? _vm.$t('homePage.marketUpdate.foreign') : (item.name === 'individual' ? _vm.$t('homePage.marketUpdate.local') : '')))+"\n    ")]}},{key:"cell(buy)",fn:function(ref){
            var item = ref.item;
return [_vm._v("\n      "+_vm._s(_vm.formatNumber(item.buy, 0))+"\n    ")]}},{key:"cell(sell)",fn:function(ref){
            var item = ref.item;
return [_vm._v("\n      "+_vm._s(_vm.formatNumber(item.sell, 0))+"\n    ")]}},{key:"cell(net)",fn:function(ref){
            var item = ref.item;
return [_c('div',{class:_vm.integerStyle(item.net)},[_vm._v("\n        "+_vm._s(_vm.formatNumberInteger(item.net, 0))+"\n      ")])]}}])}):(_vm.category.includes('optionVolume'))?_c('TableCustomField',{attrs:{"fields":_vm.fields,"items":_vm.items,"selectable":false,"table-hover":false},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
            var item = ref.item;
return [_vm._v("\n      "+_vm._s(item.name === 'institution' ? _vm.$t('homePage.marketUpdate.institution') : (item.name === 'foreign' ? _vm.$t('homePage.marketUpdate.foreign') : (item.name === 'individual' ? _vm.$t('homePage.marketUpdate.local') : '')))+"\n    ")]}},{key:"cell(buy)",fn:function(ref){
            var item = ref.item;
return [_vm._v("\n      "+_vm._s(_vm.formatNumber(item.buy, 0))+"\n    ")]}},{key:"cell(sell)",fn:function(ref){
            var item = ref.item;
return [_vm._v("\n      "+_vm._s(_vm.formatNumber(item.sell, 0))+"\n    ")]}},{key:"cell(net)",fn:function(ref){
            var item = ref.item;
return [_c('div',{class:_vm.integerStyle(item.net)},[_vm._v("\n        "+_vm._s(_vm.formatNumberInteger(item.net, 0))+"\n      ")])]}}])}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }