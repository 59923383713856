//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'HomeSetNews',
  data () {
    return {
      news: [],
      newPageSize: 8,
      loading: false,
      currentTab: 'company',
      currentCalendarTab: '',
      contentMarketAlert: null
    }
  },
  computed: {
    readMore () {
      if (this.currentTab === 'company') {
        return this.localePath(
          '/market/news-and-alert/news?newsType=' + this.currentTab
        )
      } else if (this.currentTab === 'regulator') {
        return this.localePath(
          '/market/news-and-alert/news?newsType=' + this.currentTab
        )
      } else {
        return this.localePath('/market/news-and-alert/market-alerts')
      }
    },
    eventName () {
      if (this.currentTab === 'company') {
        return 'home17'
      } else if (this.currentTab === 'regulator') {
        return 'home19'
      } else {
        return 'home21'
      }
    },
    viewMoreCalendar () {
      if (this.currentCalendarTab === 'ปฏิทินหลักทรัพย์') {
        return this.localePath('/market/stock-calendar/x-calendar')
      } else {
        return this.localePath('/about/event-calendar/event/overview')
      }
    }
  },
  async mounted () {
    await Promise.all([
      this.getSetNews('company'),
      this.getMarketAlert()
    ])
  },
  methods: {
    hideDivider (index, newsLength) {
      if ((newsLength % 2 === 1 && index === newsLength - 1)) {
        return true
      }
      if (newsLength % 2 === 0 && newsLength <= 4 && index === newsLength - 1) { // 2, 4
        return true
      }
      if (newsLength % 2 === 0 && newsLength > 4 && index >= newsLength - 2) {
        return true
      }
      return false
    },
    async getSetNews (tab) {
      this.currentTab = tab
      try {
        this.loading = true
        if (tab.includes('company') || tab.includes('regulator')) {
          await this.$axios
            .get(`/api/set/news/search?sourceId=${tab}&lang=${this.$i18n.locale}`)
            .then((res) => {
              if (res.data && res.data.totalCount >= this.newPageSize) {
                this.news = res.data.newsInfoList.slice(0, this.newPageSize)
              } else {
                this.news = res.data.newsInfoList
              }
            })
        } else if (this.contentMarketAlert) {
          this.news = await this.contentMarketAlert
        } else {
          await this.$axios.get(`/api/set/news/market-alert?lang=${this.$i18n.locale}`).then((res) => {
            if (res.data && res.data.totalCount >= this.newPageSize) {
              this.news = res.data.newsInfoList.slice(0, this.newPageSize)
            } else {
              this.news = res.data.newsInfoList
            }
          })
        }
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
    setCurrentCalendarTab (tab) {
      this.currentCalendarTab = tab
    },
    async getMarketAlert () {
      await this.$axios.get(`/api/set/news/market-alert?lang=${this.$i18n.locale}`).then((res) => {
        if (res.data && res.data.totalCount >= this.newPageSize) {
          this.contentMarketAlert = res.data.newsInfoList.slice(0, this.newPageSize)
        } else {
          this.contentMarketAlert = res.data.newsInfoList
        }
      })
    },
    handleClickTrackingViewMoreNews () {
      this.$personalized.clickElement({
        name: this.eventName,
        optionLang: {
          buttonCaption: this.$t('buttons.viewMore')
        }
      })
    },

    eventTrackingViewMore () {
      this.$personalized.clickElement({
        name: this.currentCalendarTab === 'ปฏิทินหลักทรัพย์' ? 'home24' : 'home25'
      })
    }
  }
}
